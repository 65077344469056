import { Injectable, Optional } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { fromEvent, of, throwError } from 'rxjs';
import { catchError, map, mapTo, switchMap, tap } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { MatSnackBar } from '@angular/material/snack-bar';

import { go, refresh } from '@digital-cap-fe/state/router-state';
import { MonitoringService } from '@digital-cap-fe/platform/monitoring';
import { getErrorDescription } from '@digital-cap-fe/error-codes';

import * as authActions from './auth.actions';

import { TokenService } from '../services/token.service';
import { UserService } from '../services/user.service';
import { Store } from '@ngrx/store';

const refreshTokenStorageKey = 'refreshToken';
export const languageStorageKey = 'language';

@Injectable()
export class AuthEffects {
    init$ = createEffect(() =>
        this.actions$.pipe(
            ofType(authActions.initAuth),
            switchMap(() => {
                const refreshToken = localStorage.getItem(
                    refreshTokenStorageKey,
                );
                if (refreshToken !== null) {
                    return [
                        authActions.restoreRefreshToken({ refreshToken }),
                        authActions.initialization({ refreshNeeded: false }),
                    ];
                } else {
                    return [authActions.stopInitialization()];
                }
            }),
        ),
    );

    storeToken$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(authActions.storeToken),
                tap((tokenPayload) => {
                    localStorage.setItem(
                        refreshTokenStorageKey,
                        tokenPayload.refreshToken,
                    );
                }),
            ),
        {
            dispatch: false,
        },
    );

    appInit$ = createEffect(() =>
        this.actions$.pipe(
            ofType(authActions.initialization),
            switchMap(({ refreshNeeded }) =>
                this.userService.getCurrentUserDetails().pipe(
                    tap(({ email, name, permissions, language }) => {
                        this.monitoringService?.identifyUser(email, {
                            name,
                            email,
                            permissionCount: permissions.length,
                        });
                        localStorage.setItem(languageStorageKey, language);
                        if (refreshNeeded) {
                            this.store.dispatch(refresh());
                        }
                    }),
                    switchMap((user) => [
                        authActions.initializationSuccess({ user }),
                    ]),
                    catchError((err) => {
                        console.error(err);
                        return of(authActions.initializationFailed());
                    }),
                ),
            ),
        ),
    );

    tokenRefresh$ = createEffect(() =>
        this.actions$.pipe(
            ofType(authActions.refreshAuthToken),
            switchMap(() =>
                this.tokenService.refreshToken().pipe(
                    map((token) => authActions.refreshAuthTokenSuccess(token)),
                    catchError((err) => this.handleAuthError(err)),
                    catchError((err) =>
                        of(
                            authActions.refreshAuthTokenFailed({
                                errorMessage: err.message,
                                statusCode: err.status,
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );

    sendPasswordResetEmail$ = createEffect(() =>
        this.actions$.pipe(
            ofType(authActions.requestPasswordReset),
            switchMap(({ email }) =>
                this.userService.requestPasswordResetEmail(email).pipe(
                    tap(() => {
                        this.snackBar.open(
                            $localize`:@@passwordResetSuccessfullyRequested:Password change request sent to ${email}`,
                            undefined,
                            {
                                duration: 8000,
                            },
                        );
                    }),
                    switchMap(() => {
                        return [
                            authActions.requestPasswordResetSuccess(),
                            go({ commands: ['/'] }),
                        ];
                    }),
                    catchError(({ error, status }) =>
                        of(
                            authActions.requestPasswordResetFailed({
                                errorMessage: getErrorDescription(error),
                                statusCode: status,
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );

    setPassword$ = createEffect(() =>
        this.actions$.pipe(
            ofType(authActions.setPassword),
            switchMap(({ password }) =>
                this.userService.patchCurrentUser({ password }).pipe(
                    tap(() => {
                        this.snackBar.open(
                            $localize`:@@passwordSuccessfullyChanged:Successfully set new password`,
                            undefined,
                            {
                                duration: 4000,
                            },
                        );
                    }),
                    switchMap(() => {
                        return [
                            authActions.setPasswordSuccess(),
                            go({ commands: ['/'] }),
                        ];
                    }),
                    catchError(({ error, status }) =>
                        of(
                            authActions.setPasswordFailed({
                                errorMessage: getErrorDescription(error),
                                statusCode: status,
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );

    setLanguage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(authActions.setLanguage),
            switchMap(({ language }) =>
                this.userService.patchCurrentUser({ language }).pipe(
                    tap(async () =>
                        localStorage.setItem(languageStorageKey, language),
                    ),
                    switchMap(() => [
                        authActions.setLanguageSuccess({ language }),
                        refresh(),
                    ]),
                    catchError(({ error, status }) =>
                        of(
                            authActions.setLanguageFailed({
                                errorMessage: getErrorDescription(error),
                                statusCode: status,
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );

    setLanguageSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(authActions.setLanguageSuccess),
                tap(({ language }) =>
                    this.snackBar.open(
                        $localize`:@@languageSuccessfullyChanged:Successfully set language`,
                        undefined,
                        {
                            duration: 4000,
                        },
                    ),
                ),
            ),
        { dispatch: false },
    );

    authErrorNotifications$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    authActions.setPasswordFailed,
                    authActions.setLanguageFailed,
                    authActions.requestPasswordResetFailed,
                ),
                tap(({ errorMessage }) =>
                    this.snackBar.open(errorMessage, undefined, {
                        duration: 8000,
                    }),
                ),
            ),
        { dispatch: false },
    );

    logout$ = createEffect(() =>
        this.actions$.pipe(
            ofType(authActions.logout),
            switchMap(() =>
                this.tokenService.deleteToken().pipe(
                    switchMap(() => {
                        return [authActions.logoutSuccess()];
                    }),
                ),
            ),
        ),
    );

    logoutSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(authActions.logoutSuccess),
            tap(() => {
                localStorage.removeItem(refreshTokenStorageKey);
                localStorage.removeItem(languageStorageKey);
                location.reload();
            }),
            mapTo(go({ commands: ['/login'] })),
        ),
    );

    reconnect$ = createEffect(() =>
        fromEvent(window, 'online').pipe(map(() => authActions.initAuth())),
    );

    ngrxOnInitEffects() {
        return authActions.initAuth();
    }

    private handleAuthError(error: HttpErrorResponse | Error) {
        if (error instanceof HttpErrorResponse && error.status === 401) {
            return of(authActions.logoutSuccess());
        }

        return throwError(() => error);
    }

    constructor(
        private actions$: Actions,
        private tokenService: TokenService,
        private userService: UserService,
        private snackBar: MatSnackBar,
        private store: Store,
        @Optional() private monitoringService: MonitoringService | null,
    ) {}
}
