import { NavigationExtras } from '@angular/router';

import { createAction, props } from '@ngrx/store';

export const go = createAction('[Router] Go', props<GoParameters>());

export const goAndCloseModal = createAction(
    '[Router] Go and close modal',
    props<GoParameters>(),
);

export const closeModalOutlet = (modalOutletName = 'modal-outlet') =>
    go({
        commands: [
            '',
            {
                outlets: {
                    [modalOutletName]: null,
                },
            },
        ],
    });

export const back = createAction('[Router] Back');

export const forward = createAction('[Router] Forward');

export const refresh = createAction('[Router] Refresh');

interface GoParameters {
    commands: (string | number | object)[];
    extras?: NavigationExtras;
}
