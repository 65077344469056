import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import {
    DEFAULT_ROUTER_FEATURENAME,
    routerReducer,
    StoreRouterConnectingModule,
    NavigationActionTiming,
    RouterState,
} from '@ngrx/router-store';

import { RouterEffects } from './state/router.effects';

@NgModule({
    imports: [
        RouterModule,
        StoreModule.forFeature(DEFAULT_ROUTER_FEATURENAME, routerReducer),
        EffectsModule.forFeature([RouterEffects]),
        StoreRouterConnectingModule.forRoot({
            routerState: RouterState.Minimal,
            navigationActionTiming: NavigationActionTiming.PostActivation,
        }),
    ],
})
export class RouterStateModule {}
