import { Injectable } from '@angular/core';

import LogRocket from 'logrocket';

interface IUserTraits {
    [propName: string]: string | number | boolean;
}

type TrackEventProperties = {
    revenue?: number;
    [key: string]:
        | string
        | number
        | boolean
        | string[]
        | number[]
        | boolean[]
        | undefined;
};

@Injectable()
export class MonitoringService {
    identifyUser(email: string, userDetails: IUserTraits) {
        LogRocket.identify(email, userDetails);
    }

    logError(error: Error | any) {
        LogRocket.captureException(error);
    }

    trackEvent(eventName: string, eventProperties?: TrackEventProperties) {
        LogRocket.track(eventName, eventProperties as any);
    }
}
