export function isValidUTF8(buffer: ArrayBuffer): boolean {
    let index = 0;
    const data = new Uint8Array(buffer);
    while (index < data.byteLength) {
        const first = data[index];
        if (first < 0x80) {
            // UTF-8 0wwwwwww ==> Unicode 0wwwwwww
            index += 1;
        } else if ((first & 0xe0) === 0xc0) {
            // UTF-8 110wwwww 10xxxxxx ==> Unicode 00000www wwxxxxxx
            const second = data[index + 1];
            if ((second & 0xc0) !== 0x80 || (first & 0xfe) === 0xc0) {
                // broken UTF8 code or overlong UTF8 code
                return false;
            }
            index += 2;
        } else if ((first & 0xf0) === 0xe0) {
            // UTF-8 1110wwww 10xxxxxx 10yyyyyy ==> Unicode wwwwxxxx xxyyyyyy
            const second = data[index + 1];
            const third = data[index + 2];
            if (
                (second & 0xc0) !== 0x80 ||
                (third & 0xc0) !== 0x80 ||
                (first === 0xe0 && (second & 0xe0) === 0x80) ||
                (first === 0xed && (second & 0xe0) === 0xa0) ||
                (first === 0xef && second === 0xbf && (third & 0xfe) === 0xbe)
            ) {
                // broken UTF8 code or overlong UTF8 code or surrogate UTF8 code or 0xFFFE/0xFFFF
                return false;
            }
            index += 3;
        } else if ((first & 0xf8) === 0xf0) {
            // UTF-8 11110www 10xxxxxx 10yyyyyy 10zzzzzz ==> Unicode 000wwwxx xxxxyyyy yyzzzzzz
            const second = data[index + 1];
            const third = data[index + 2];
            const fourth = data[index + 3];
            if (
                (second & 0xc0) !== 0x80 ||
                (third & 0xc0) !== 0x80 ||
                (fourth & 0xc0) !== 0x80 ||
                (first === 0xf0 && (second & 0xf0) === 0x80) ||
                (first === 0xf4 && second > 0x8f) ||
                first > 0xf4
            ) {
                // broken UTF8 code or overlong UTF8 code or greater than 0x10FFFF (max of UTF16)
                return false;
            }
            index += 4;
        } else {
            // other non-UTF8 code
            return false;
        }
    }
    return true;
}
