import { InjectionToken } from '@angular/core';

export interface DigitalCapFrontendEnvironment {
    production: boolean;
    passwordSalt: string;
    passwordCryptoOptions: { iterations: number; keySize: number };
}

export const ENVIRONMENT_TOKEN =
    new InjectionToken<DigitalCapFrontendEnvironment>(
        'Environment token ENVIRONMENT_TOKEN',
    );
