/**
 * API definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PODFilter = 'ALL' | 'ASSIGNED' | 'NON_ASSIGNED';

export const PODFilter = {
    All: 'ALL' as PODFilter,
    Assigned: 'ASSIGNED' as PODFilter,
    NonAssigned: 'NON_ASSIGNED' as PODFilter
};

