import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import { tap } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { go, back, forward, refresh, goAndCloseModal } from './router.actions';

@Injectable()
export class RouterEffects {
    go$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(go),
                tap((payload) => {
                    this.router.navigate(payload.commands, payload.extras);
                }),
            );
        },
        { dispatch: false },
    );

    goAndCloseModal$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(goAndCloseModal),
                tap((payload) => {
                    this.router
                        .navigate(payload.commands, payload.extras)
                        .then(() =>
                            this.router.navigate([
                                '',
                                {
                                    outlets: { 'modal-outlet': null },
                                },
                            ]),
                        );
                }),
            );
        },
        { dispatch: false },
    );

    back$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(back),
                tap(() => {
                    this.location.back();
                }),
            );
        },
        { dispatch: false },
    );

    forward$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(forward),
                tap(() => {
                    this.location.forward();
                }),
            );
        },
        { dispatch: false },
    );

    refresh$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(refresh),
                tap(() => {
                    document.location.reload();
                }),
            );
        },
        { dispatch: false },
    );

    constructor(
        private actions$: Actions,
        private router: Router,
        private location: Location,
    ) {}
}
