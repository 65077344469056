import { createAction, props } from '@ngrx/store';

import { TokenResponse, User } from '@digital-cap-fe/models';

export const initAuth = createAction('[Auth] Init');

export const storeToken = createAction(
    '[Auth] Store Token',
    props<TokenResponse>(),
);

export const restoreRefreshToken = createAction(
    '[Auth] Restore Refresh Token',
    props<{ refreshToken: string }>(),
);

export const initialization = createAction(
    '[Auth] Start Initialization',
    props<{ refreshNeeded: boolean }>(),
);

export const initializationSuccess = createAction(
    '[Auth] Initialization Success',
    props<{ user: User }>(),
);

export const initializationFailed = createAction(
    '[Auth] Initialization Failed',
);

export const stopInitialization = createAction('[Auth] Stop Initialization');

export const setLanguage = createAction(
    '[Auth] Set language',
    props<{ language: string }>(),
);

export const setLanguageSuccess = createAction(
    '[Auth] Set language success',
    props<{ language: string }>(),
);

export const setLanguageFailed = createAction(
    '[Auth] Set language Failed',
    props<{ errorMessage: string; statusCode?: number }>(),
);

export const setPassword = createAction(
    '[Auth] Set password',
    props<{ password: string }>(),
);

export const setPasswordSuccess = createAction('[Auth] Set password success');

export const setPasswordFailed = createAction(
    '[Auth] Set password Failed',
    props<{ errorMessage: string; statusCode?: number }>(),
);

export const requestPasswordReset = createAction(
    '[Auth] Request Password Reset',
    props<{ email: string }>(),
);

export const requestPasswordResetSuccess = createAction(
    '[Auth] Request Password Reset success',
);

export const requestPasswordResetFailed = createAction(
    '[Auth] Request Password Reset Failed',
    props<{ errorMessage: string; statusCode?: number }>(),
);

export const logout = createAction('[Auth] Logout');

export const logoutSuccess = createAction('[Auth] Logout Success');

export const refreshAuthToken = createAction('[Auth] Refresh Token');

export const refreshAuthTokenSuccess = createAction(
    '[Auth] Refresh Token Success',
    props<TokenResponse>(),
);

export const refreshAuthTokenFailed = createAction(
    '[Auth] Refresh Token Failed',
    props<{ errorMessage: string; statusCode?: number }>(),
);
