import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';

import { ApiMessagesEffects } from './api-messages.effects';
import { WebsocketService } from './websocket.service';

@NgModule({
    imports: [EffectsModule.forFeature([ApiMessagesEffects])],
    providers: [WebsocketService],
})
export class ApiMessagesModule {}
