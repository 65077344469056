/**
 * API definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
    HttpClient,
    HttpHeaders,
    HttpParams,
    HttpResponse,
    HttpEvent,
    HttpParameterCodec,
    HttpContext,
} from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

// @ts-ignore
import { InstallationGridData } from '../model/installationGridData';
// @ts-ignore
import { InstallationInput } from '../model/installationInput';
// @ts-ignore
import { InstallationRead } from '../model/installationRead';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
    providedIn: 'root',
})
export class ImpCapInstallationService {
    protected basePath = 'https://test.digitalcap.network';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(
        protected httpClient: HttpClient,
        @Optional() @Inject(BASE_PATH) basePath: string | string[],
        @Optional() configuration: Configuration,
    ) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder =
            this.configuration.encoder || new CustomHttpParameterCodec();
    }

    // @ts-ignore
    private addToHttpParams(
        httpParams: HttpParams,
        value: any,
        key?: string,
    ): HttpParams {
        if (typeof value === 'object' && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(
        httpParams: HttpParams,
        value?: any,
        key?: string,
    ): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === 'object') {
            if (Array.isArray(value)) {
                (value as any[]).forEach(
                    (elem) =>
                        (httpParams = this.addToHttpParamsRecursive(
                            httpParams,
                            elem,
                            key,
                        )),
                );
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(
                        key,
                        (value as Date).toISOString().substr(0, 10),
                    );
                } else {
                    throw Error('key may not be null if value is Date');
                }
            } else {
                Object.keys(value).forEach(
                    (k) =>
                        (httpParams = this.addToHttpParamsRecursive(
                            httpParams,
                            value[k],
                            key != null ? `${key}.${k}` : k,
                        )),
                );
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error('key may not be null if value is not object or array');
        }
        return httpParams;
    }

    /**
     * Create a new installation
     * @param installationInput
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createInstallation(
        installationInput: InstallationInput,
        observe?: 'body',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?: 'application/json';
            context?: HttpContext;
        },
    ): Observable<number>;
    public createInstallation(
        installationInput: InstallationInput,
        observe?: 'response',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?: 'application/json';
            context?: HttpContext;
        },
    ): Observable<HttpResponse<number>>;
    public createInstallation(
        installationInput: InstallationInput,
        observe?: 'events',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?: 'application/json';
            context?: HttpContext;
        },
    ): Observable<HttpEvent<number>>;
    public createInstallation(
        installationInput: InstallationInput,
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: {
            httpHeaderAccept?: 'application/json';
            context?: HttpContext;
        },
    ): Observable<any> {
        if (installationInput === null || installationInput === undefined) {
            throw new Error(
                'Required parameter installationInput was null or undefined when calling createInstallation.',
            );
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined =
            options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = ['application/json'];
            localVarHttpHeaderAcceptSelected =
                this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set(
                'Accept',
                localVarHttpHeaderAcceptSelected,
            );
        }

        let localVarHttpContext: HttpContext | undefined =
            options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        // to determine the Content-Type header
        const consumes: string[] = ['application/json'];
        const httpContentTypeSelected: string | undefined =
            this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set(
                'Content-Type',
                httpContentTypeSelected,
            );
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (
                this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
            ) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/demo/v1/impcap/installations`;
        return this.httpClient.request<number>(
            'post',
            `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: installationInput,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            },
        );
    }

    /**
     * Delete installation by installationId
     * @param installationId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteInstallation(
        installationId: number,
        observe?: 'body',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: undefined; context?: HttpContext },
    ): Observable<any>;
    public deleteInstallation(
        installationId: number,
        observe?: 'response',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: undefined; context?: HttpContext },
    ): Observable<HttpResponse<any>>;
    public deleteInstallation(
        installationId: number,
        observe?: 'events',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: undefined; context?: HttpContext },
    ): Observable<HttpEvent<any>>;
    public deleteInstallation(
        installationId: number,
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: { httpHeaderAccept?: undefined; context?: HttpContext },
    ): Observable<any> {
        if (installationId === null || installationId === undefined) {
            throw new Error(
                'Required parameter installationId was null or undefined when calling deleteInstallation.',
            );
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined =
            options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [];
            localVarHttpHeaderAcceptSelected =
                this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set(
                'Accept',
                localVarHttpHeaderAcceptSelected,
            );
        }

        let localVarHttpContext: HttpContext | undefined =
            options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (
                this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
            ) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/demo/v1/impcap/installations/${this.configuration.encodeParam(
            {
                name: 'installationId',
                value: installationId,
                in: 'path',
                style: 'simple',
                explode: false,
                dataType: 'number',
                dataFormat: undefined,
            },
        )}`;
        return this.httpClient.request<any>(
            'delete',
            `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            },
        );
    }

    /**
     * Get installation details by installationId
     * @param installationId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getInstallationDetails(
        installationId: number,
        observe?: 'body',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?: 'application/json';
            context?: HttpContext;
        },
    ): Observable<InstallationRead>;
    public getInstallationDetails(
        installationId: number,
        observe?: 'response',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?: 'application/json';
            context?: HttpContext;
        },
    ): Observable<HttpResponse<InstallationRead>>;
    public getInstallationDetails(
        installationId: number,
        observe?: 'events',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?: 'application/json';
            context?: HttpContext;
        },
    ): Observable<HttpEvent<InstallationRead>>;
    public getInstallationDetails(
        installationId: number,
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: {
            httpHeaderAccept?: 'application/json';
            context?: HttpContext;
        },
    ): Observable<any> {
        if (installationId === null || installationId === undefined) {
            throw new Error(
                'Required parameter installationId was null or undefined when calling getInstallationDetails.',
            );
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined =
            options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = ['application/json'];
            localVarHttpHeaderAcceptSelected =
                this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set(
                'Accept',
                localVarHttpHeaderAcceptSelected,
            );
        }

        let localVarHttpContext: HttpContext | undefined =
            options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (
                this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
            ) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/demo/v1/impcap/installations/${this.configuration.encodeParam(
            {
                name: 'installationId',
                value: installationId,
                in: 'path',
                style: 'simple',
                explode: false,
                dataType: 'number',
                dataFormat: 'int64',
            },
        )}`;
        return this.httpClient.request<InstallationRead>(
            'get',
            `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            },
        );
    }

    /**
     * Get all installations
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getInstallations(
        observe?: 'body',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?: 'application/json';
            context?: HttpContext;
        },
    ): Observable<Array<InstallationGridData>>;
    public getInstallations(
        observe?: 'response',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?: 'application/json';
            context?: HttpContext;
        },
    ): Observable<HttpResponse<Array<InstallationGridData>>>;
    public getInstallations(
        observe?: 'events',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?: 'application/json';
            context?: HttpContext;
        },
    ): Observable<HttpEvent<Array<InstallationGridData>>>;
    public getInstallations(
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: {
            httpHeaderAccept?: 'application/json';
            context?: HttpContext;
        },
    ): Observable<any> {
        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined =
            options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = ['application/json'];
            localVarHttpHeaderAcceptSelected =
                this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set(
                'Accept',
                localVarHttpHeaderAcceptSelected,
            );
        }

        let localVarHttpContext: HttpContext | undefined =
            options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (
                this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
            ) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/demo/v1/impcap/installations`;
        return this.httpClient.request<Array<InstallationGridData>>(
            'get',
            `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            },
        );
    }

    /**
     * Update installation by installationId
     * @param installationId
     * @param installationInput
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateInstallation(
        installationId: number,
        installationInput: InstallationInput,
        observe?: 'body',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: undefined; context?: HttpContext },
    ): Observable<any>;
    public updateInstallation(
        installationId: number,
        installationInput: InstallationInput,
        observe?: 'response',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: undefined; context?: HttpContext },
    ): Observable<HttpResponse<any>>;
    public updateInstallation(
        installationId: number,
        installationInput: InstallationInput,
        observe?: 'events',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: undefined; context?: HttpContext },
    ): Observable<HttpEvent<any>>;
    public updateInstallation(
        installationId: number,
        installationInput: InstallationInput,
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: { httpHeaderAccept?: undefined; context?: HttpContext },
    ): Observable<any> {
        if (installationId === null || installationId === undefined) {
            throw new Error(
                'Required parameter installationId was null or undefined when calling updateInstallation.',
            );
        }
        if (installationInput === null || installationInput === undefined) {
            throw new Error(
                'Required parameter installationInput was null or undefined when calling updateInstallation.',
            );
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined =
            options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [];
            localVarHttpHeaderAcceptSelected =
                this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set(
                'Accept',
                localVarHttpHeaderAcceptSelected,
            );
        }

        let localVarHttpContext: HttpContext | undefined =
            options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        // to determine the Content-Type header
        const consumes: string[] = ['application/json'];
        const httpContentTypeSelected: string | undefined =
            this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set(
                'Content-Type',
                httpContentTypeSelected,
            );
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (
                this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
            ) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/demo/v1/impcap/installations/${this.configuration.encodeParam(
            {
                name: 'installationId',
                value: installationId,
                in: 'path',
                style: 'simple',
                explode: false,
                dataType: 'number',
                dataFormat: 'int64',
            },
        )}`;
        return this.httpClient.request<any>(
            'put',
            `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: installationInput,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            },
        );
    }
}
