import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Route, RouterModule, ROUTES, Routes } from '@angular/router';
import { ModalWrapperComponent } from './modal-wrapper/modal-wrapper.component';
import { IS_ROUTED_MODAL, MODAL_OUTLET } from './modal-config.tokens';

@NgModule({
    imports: [CommonModule, RouterModule],
    declarations: [ModalWrapperComponent],
    exports: [ModalWrapperComponent],
})
export class ModalModule {
    static withChildRoutes({
        path,
        outlet,
        children,
    }: ModalOptions): ModuleWithProviders<ModalModule> {
        const mainRoute: Route = {
            path: path ?? '',
            outlet: outlet ?? undefined,
            component: ModalWrapperComponent,
            children,
        };

        return {
            ngModule: ModalModule,
            providers: [
                {
                    provide: ROUTES,
                    multi: true,
                    useValue: [mainRoute],
                },
                { provide: IS_ROUTED_MODAL, useValue: true },
                { provide: MODAL_OUTLET, useValue: mainRoute.outlet },
            ],
        };
    }
}

interface ModalOptions {
    path?: string;
    outlet?: string;
    children: Routes;
}
