<div
    class="main-wrapper"
    @disappear
    *ngIf="(initInProgress | async) && (initialized | async) === false"
>
    <div class="card loading-indicator animated" @scaleAndMoveUp>
        <digital-cap-logo></digital-cap-logo>
        <!-- <svg
            width="134"
            height="117"
            viewBox="0 0 134 117"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M117.697 29.2574L103.013 3.75372C101.676 1.43091 99.2043 0 96.5296 0H67.1628H46.4405C40.6774 0 37.0755 6.25621 39.957 11.2612L50.3181 29.2574L67.0054 58.2415L51.9716 84.3538C50.9017 86.212 48.9245 87.3567 46.7848 87.3567H26.927C22.3165 87.3567 19.435 82.3518 21.7402 78.3478L33.398 58.0994L43.7156 40.1513C46.5885 35.1536 43.001 28.909 37.2485 28.8945L20.8883 28.853C18.2069 28.8462 15.7267 30.278 14.3859 32.6067L1.86997 54.3457C0.532641 56.6685 0.532641 59.5303 1.86997 61.8531L16.5534 87.3567L31.2369 112.86C32.5742 115.183 35.0457 116.614 37.7203 116.614H67.0872H87.8095C93.5726 116.614 97.1745 110.358 94.2929 105.353L83.9318 87.3567L67.2446 58.3726L82.2784 32.2603C83.3483 30.4021 85.3255 29.2574 87.4652 29.2574H107.323C111.933 29.2574 114.815 34.2623 112.51 38.2663L100.852 58.5147L90.5344 76.4628C87.6615 81.4605 91.249 87.7051 97.0015 87.7197L113.362 87.7611C116.043 87.7679 118.523 86.3361 119.864 84.0074L132.38 62.2685C133.717 59.9457 133.717 57.0838 132.38 54.761L117.697 29.2574Z"
                fill="#27064D"
            />
        </svg> -->
    </div>
</div>
<div class="offline-alert" *ngIf="!isOnline">
    <div class="card">
        <h5
            i18n="
                trying to reconnect|Shown to the user when the browser lost
                connection@@reconnectAttempt"
        >
            Trying to reconnect
            <fa-icon [icon]="loadingIcon" [spin]="true" size="sm"></fa-icon>
        </h5>
    </div>
</div>
<router-outlet></router-outlet>
