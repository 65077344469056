import { Injectable } from '@angular/core';

import { webSocket } from 'rxjs/webSocket';
import { switchMap, first } from 'rxjs/operators';

import { AuthState } from '@digital-cap-fe/platform/auth';
import { isNonNullish } from '@digital-cap-fe/utilities';

@Injectable()
export class WebsocketService {
    subscribeMessages() {
        return this.authState.getAuthToken().pipe(
            first(isNonNullish),
            switchMap((token) => {
                const url = `ws://localhost:4200/ws/messages?token=${token}`;
                return webSocket<SocketMessage>(url).asObservable().pipe();
            }),
        );
    }

    constructor(private authState: AuthState) {}
}

export interface SocketMessage {
    payload: object[];
    deletedIds?: number[];
    messageType: string;
}
