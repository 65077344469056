/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpParams } from '@angular/common/http';

import { isNonNullish } from './nullish-check';
import { isDate } from './date';

export type QueryValue<T = any> = {
    greaterThan?: T;
    lessThan?: T;
    greaterThanOrEqual?: T;
    lessThanOrEqual?: T;
    contains?: T;
};

export type QueryParamValue<T = any> = T | QueryValue<T>;

export type QueryParams = { [index: string]: QueryParamValue };

type ParsedQueryParam = {
    name: string;
    value: string;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export type EndpointParameters<T extends object> = {
    [P in keyof T]: T[P] | QueryValue<T[P]>;
};

export function parseQueryParams(queryParameters: QueryParams) {
    return Object.keys(queryParameters).reduce(
        (parsedParamAccumulator, paramKey) =>
            parseQueryParam(paramKey, queryParameters[paramKey]).reduce(
                (temporaryParsedParameterAcc, { name, value }) =>
                    temporaryParsedParameterAcc.set(name, value),
                parsedParamAccumulator,
            ),
        new HttpParams(),
    );
}

function parseQueryParam(
    name: string,
    value: QueryParamValue,
): ParsedQueryParam[] {
    if (isNonNullish(value) && value.length !== 0) {
        switch (typeof value) {
            case 'string':
            case 'number':
            case 'bigint':
                return [{ name, value: value.toString() }];
            case 'object':
            default: {
                if (isQueryValue(value)) {
                    const returnArray: ParsedQueryParam[] = [];

                    if (isNonNullish(value.greaterThan)) {
                        returnArray.push({
                            name: `${name}[gt]`,
                            value: serialize(value.greaterThan),
                        });
                    }

                    if (isNonNullish(value.lessThan)) {
                        returnArray.push({
                            name: `${name}[lt]`,
                            value: serialize(value.lessThan),
                        });
                    }

                    if (isNonNullish(value.greaterThanOrEqual)) {
                        returnArray.push({
                            name: `${name}[gte]`,
                            value: serialize(value.greaterThanOrEqual),
                        });
                    }

                    if (isNonNullish(value.lessThanOrEqual)) {
                        returnArray.push({
                            name: `${name}[lte]`,
                            value: serialize(value.lessThanOrEqual),
                        });
                    }

                    if (isNonNullish(value.contains)) {
                        returnArray.push({
                            name: `${name}[icontains]`,
                            value: serialize(value.contains),
                        });
                    }

                    return returnArray;
                }

                return [{ name, value: serialize(value) }];
            }
        }
    } else {
        return [];
    }
}

export function isQueryValue(value: any): value is QueryValue {
    return (
        isNonNullish(value) &&
        typeof value === 'object' &&
        ('greaterThan' in value ||
            'lessThan' in value ||
            'greaterThanOrEqual' in value ||
            'lessThanOrEqual' in value ||
            'contains' in value)
    );
}

export function serialize(value: any): string {
    if (isDate(value)) {
        return value.toJSON();
    }

    if (Array.isArray(value)) {
        return JSON.stringify(value);
    }

    try {
        return value.toString();
    } catch {
        return JSON.stringify(value);
    }
}

const excludedFilterKeys = ['limit', 'offset'];

export function queryParamFilterPredicate<T extends { [index: string]: any }>(
    query: QueryParams,
) {
    return (newValue: T) => {
        return Object.keys(query)
            .filter((key) => !excludedFilterKeys.includes(key))
            .every((filterKey) => {
                const filterValue = query[filterKey];
                const valueToBeChecked = newValue[filterKey];

                if (isNonNullish(filterValue)) {
                    if (Array.isArray(filterValue)) {
                        if (filterValue.length === 0) {
                            return true;
                        }

                        return filterValue.includes(valueToBeChecked);
                    } else if (isQueryValue(filterValue)) {
                        if (
                            isNonNullish(filterValue.greaterThan) &&
                            valueToBeChecked < filterValue.greaterThan
                        ) {
                            return false;
                        }

                        if (
                            isNonNullish(filterValue.lessThan) &&
                            valueToBeChecked > filterValue.lessThan
                        ) {
                            return false;
                        }

                        return true;
                    } else {
                        return filterValue === valueToBeChecked;
                    }
                } else {
                    return true;
                }
            });
    };
}
