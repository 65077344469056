export function isIncluded(
    filter: string,
    ...fields: (string | null | undefined)[]
) {
    return (
        !filter ||
        filter.length < 2 ||
        fields.some((field) =>
            field?.toLowerCase().includes(filter.toLowerCase()),
        )
    );
}

export function isIncludedId(filter: string, id: number, field: number | null) {
    return !filter || id === field;
}

export function isAllEmpty(...filters: string[]) {
    return filters.every((filter) => !filter);
}
