import { ErrorHandler, Injectable } from '@angular/core';

import { MonitoringService } from './monitoring.service';

@Injectable()
export class MonitoringErrorHandler implements ErrorHandler {
    constructor(private monitoringService: MonitoringService) {}

    handleError(error: Error) {
        this.monitoringService.logError(error);
        console.error(error);
    }
}
