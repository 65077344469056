import { subMonths as submonths } from 'date-fns';

export function isDate(value: unknown): value is Date {
    return value instanceof Date;
}

export function shortDateTime(locale: string): string {
    if (locale === 'hu') {
        return 'yyyy.MM.dd. HH:mm';
    }
    return 'dd/MM/yyyy HH:mm';
}

export function shortDate(locale: string): string {
    if (locale === 'hu') {
        return 'yyyy.MM.dd.';
    }
    return 'dd/MM/yyyy';
}

export function shortTimestamp(locale: string): string {
    if (locale === 'hu') {
        return 'yyyy.MM.dd. HH:mm:ss';
    }
    return 'dd/MM/yyyy HH:mm:ss';
}

export function subMonths(date: Date | number, amount: number): Date {
    return submonths(date, amount);
}
