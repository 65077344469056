import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { catchError, switchMap, map, delay } from 'rxjs/operators';

import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';

import { subscribeToAPIUpdates } from './api-messages.actions';
import { WebsocketService } from './websocket.service';

@Injectable()
export class ApiMessagesEffects implements OnInitEffects {
    deviceUpdates$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(subscribeToAPIUpdates),
            switchMap(() => {
                return this.websocketService.subscribeMessages().pipe(
                    map((message) => {
                        return {
                            type: message.messageType,
                            payload: message.payload,
                            deletedIds: message.deletedIds,
                            receivedAt: Date.now(),
                        };
                    }),
                    catchError((error: Error) => {
                        console.warn(
                            'Failed to connect to websocket, retrying in 10 seconds',
                        );
                        console.warn(error);
                        return of(subscribeToAPIUpdates()).pipe(delay(10000));
                    }),
                );
            }),
        );
    });

    ngrxOnInitEffects() {
        return subscribeToAPIUpdates();
    }

    constructor(
        private actions$: Actions,
        private websocketService: WebsocketService,
    ) {}
}
