import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { map, filter, switchMap } from 'rxjs/operators';

import { Permission } from '@digital-cap-fe/models';
import { isNonNullish } from '@digital-cap-fe/utilities';

import { AuthState } from '../state/auth.facade';

@Injectable()
export class InitGuard implements CanActivate {
    constructor(private authState: AuthState, private router: Router) {}

    canActivate() {
        return this.checkIfInitialized().pipe(
            switchMap(() => {
                return this.authState.getUser().pipe(
                    filter(isNonNullish),
                    map((user) => {
                        if (user.permissions.length === 0) {
                            this.authState.logout();
                            return this.router.createUrlTree(['login']);
                        }

                        if (
                            user.permissions.includes(
                                Permission.ADMIN_DEVICE_LISTING,
                            )
                        ) {
                            return this.router.createUrlTree([
                                'main',
                                'map-overview',
                            ]);
                        } else if (
                            user.permissions.includes(
                                Permission.STOREKEEPER_DEVICE_LISTING,
                            )
                        ) {
                            return this.router.createUrlTree([
                                'main',
                                'device-management',
                            ]);
                        } else if (
                            user.permissions.includes(
                                Permission.ADMIN_USERS_LISTING,
                            )
                        ) {
                            return this.router.createUrlTree([
                                'main',
                                'user-management',
                            ]);
                        } else if (
                            user.permissions.includes(
                                Permission.INSTALLER_DEVICE_DETAILS_OVERWRITE,
                            )
                        ) {
                            return this.router.createUrlTree([
                                'main',
                                'device-installation',
                            ]);
                        }

                        return false;
                    }),
                );
            }),
        );
    }

    checkIfInitialized() {
        return this.authState
            .isInitialized()
            .pipe(filter((isInitialized) => isInitialized));
    }
}
