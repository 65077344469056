/***************************************************************************************************
 * Load `$localize` onto the global scope - used if i18n tags appear in Angular templates.
 */
import '@angular/localize/init';

import { enableProdMode } from '@angular/core';
import { platformBrowser } from '@angular/platform-browser';

import { setVersionInformation } from '@digital-cap-fe/utilities';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { initializeLocale } from './initialize-locale';
import { LicenseManager } from '@ag-grid-enterprise/core';

LicenseManager.setLicenseKey(
    'CompanyName=Fiorentini Hungary Kft.,' +
        'LicensedApplication=DigitalCap Management System,' +
        'LicenseType=SingleApplication,' +
        'LicensedConcurrentDeveloperCount=1,' +
        'LicensedProductionInstancesCount=1,' +
        'AssetReference=AG-025917,' +
        'ExpiryDate=28_February_2023_[v2]_MTY3NzU0MjQwMDAwMA==90dd2cc0127fbab7d524efb283a90a4e',
);

if (environment.production) {
    enableProdMode();
}

initializeVersionInformation();

document.addEventListener('DOMContentLoaded', async () => {
    await initializeLocale();

    platformBrowser()
        .bootstrapModule(AppModule)
        .catch((err) => console.error(err));
});

async function initializeVersionInformation() {
    try {
        const response = await fetch('assets/version.json');
        setVersionInformation(await response.json());
    } catch {
        console.warn('Failed to initialize version information');
    }
}
