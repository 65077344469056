import { Action, createReducer, on } from '@ngrx/store';

import { User } from '@digital-cap-fe/models';

import * as authActions from './auth.actions';

export const authFeatureKey = 'auth';

export interface State {
    token: string | null;
    refreshToken: string | null;
    expiry: number | null;
    tokenRefreshInProgress: boolean;
    languageChangeInProgress: boolean;
    passwordChangeInProgress: boolean;
    passwordResetRequestInProgress: boolean;
    initInProgress: boolean;
    initialized: boolean;
    user: User | null;
}

export const initialState: State = {
    token: null,
    refreshToken: null,
    expiry: null,
    tokenRefreshInProgress: false,
    languageChangeInProgress: false,
    passwordChangeInProgress: false,
    passwordResetRequestInProgress: false,
    initInProgress: true,
    initialized: false,
    user: null,
};

const authReducer = createReducer(
    initialState,
    on(authActions.logoutSuccess, () => {
        return { ...initialState, initInProgress: false };
    }),
    on(authActions.setLanguage, (state) => {
        return { ...state, languageChangeInProgress: true };
    }),
    on(authActions.setLanguageFailed, (state) => {
        return { ...state, languageChangeInProgress: false };
    }),
    on(authActions.setLanguageSuccess, (state, { language }) => {
        const currentUser = state.user;
        const user = currentUser != null ? { ...currentUser, language } : null;
        return { ...state, languageChangeInProgress: false, user };
    }),
    on(authActions.setPassword, (state) => {
        return { ...state, passwordChangeInProgress: true };
    }),
    on(
        authActions.setPasswordFailed,
        authActions.setPasswordSuccess,
        (state) => {
            return { ...state, passwordChangeInProgress: false };
        },
    ),
    on(authActions.requestPasswordReset, (state) => {
        return { ...state, passwordResetRequestInProgress: true };
    }),
    on(
        authActions.refreshAuthTokenFailed,
        authActions.requestPasswordResetSuccess,
        (state) => {
            return { ...state, passwordResetRequestInProgress: false };
        },
    ),
    on(authActions.storeToken, (state, { expiry, refreshToken, token }) => {
        return {
            ...state,
            expiry,
            refreshToken,
            token,
            tokenRefreshInProgress: false,
        };
    }),
    on(authActions.refreshAuthToken, (state) => {
        return {
            ...state,
            tokenRefreshInProgress: true,
        };
    }),
    on(authActions.refreshAuthTokenFailed, (state) => {
        return {
            ...state,
            tokenRefreshInProgress: false,
        };
    }),
    on(
        authActions.refreshAuthTokenSuccess,
        (state, { token, refreshToken, expiry }) => {
            return {
                ...state,
                tokenRefreshInProgress: false,
                token,
                refreshToken,
                expiry,
            };
        },
    ),
    on(authActions.initialization, (state) => {
        return { ...state, initInProgress: true };
    }),
    on(authActions.initializationSuccess, (state, { user }) => {
        return { ...state, initInProgress: false, initialized: true, user };
    }),
    on(
        authActions.initializationFailed,
        authActions.stopInitialization,
        (state) => {
            return { ...state, initInProgress: false };
        },
    ),
    on(authActions.restoreRefreshToken, (state, { refreshToken }) => {
        return { ...state, refreshToken };
    }),
);

export function reducer(state: State | undefined, action: Action) {
    return authReducer(state, action);
}

export const selectToken = (state: State) => state.token;
export const selectRefreshToken = (state: State) => state.refreshToken;
export const selectExpiry = (state: State) => state.expiry;
export const selectTokenRefreshInProgress = (state: State) =>
    state.tokenRefreshInProgress;
export const selectLanguageChangeInProgress = (state: State) =>
    state.languageChangeInProgress;
export const selectPasswordChangeInProgress = (state: State) =>
    state.passwordChangeInProgress;
export const selectPasswordResetRequestInProgress = (state: State) =>
    state.passwordResetRequestInProgress;
export const selectInitInProgress = (state: State) => state.initInProgress;
export const selectInitialized = (state: State) => state.initialized;
export const selectUser = (state: State) => state.user;
