/**
 * API definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
    HttpClient,
    HttpHeaders,
    HttpParams,
    HttpResponse,
    HttpEvent,
    HttpParameterCodec,
    HttpContext,
} from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

// @ts-ignore
import { DeviceLocation } from '../model/deviceLocation';
// @ts-ignore
import { DeviceType } from '../model/deviceType';
// @ts-ignore
import { DeviceTypeSystemName } from '../model/deviceTypeSystemName';
// @ts-ignore
import { GasMeterType } from '../model/gasMeterType';
// @ts-ignore
import { KWhValue } from '../model/kWhValue';
// @ts-ignore
import { MeterDevice } from '../model/meterDevice';
// @ts-ignore
import { PODFilter } from '../model/pODFilter';
// @ts-ignore
import { Photo } from '../model/photo';
// @ts-ignore
import { Pod } from '../model/pod';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
    providedIn: 'root',
})
export class CommonService {
    protected basePath = 'https://test.digitalcap.network';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(
        protected httpClient: HttpClient,
        @Optional() @Inject(BASE_PATH) basePath: string | string[],
        @Optional() configuration: Configuration,
    ) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder =
            this.configuration.encoder || new CustomHttpParameterCodec();
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

    // @ts-ignore
    private addToHttpParams(
        httpParams: HttpParams,
        value: any,
        key?: string,
    ): HttpParams {
        if (typeof value === 'object' && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(
        httpParams: HttpParams,
        value?: any,
        key?: string,
    ): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === 'object') {
            if (Array.isArray(value)) {
                (value as any[]).forEach(
                    (elem) =>
                        (httpParams = this.addToHttpParamsRecursive(
                            httpParams,
                            elem,
                            key,
                        )),
                );
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(
                        key,
                        (value as Date).toISOString().substr(0, 10),
                    );
                } else {
                    throw Error('key may not be null if value is Date');
                }
            } else {
                Object.keys(value).forEach(
                    (k) =>
                        (httpParams = this.addToHttpParamsRecursive(
                            httpParams,
                            value[k],
                            key != null ? `${key}.${k}` : k,
                        )),
                );
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error('key may not be null if value is not object or array');
        }
        return httpParams;
    }

    /**
     * Convert cubic meters to kilowatt-hours
     * @param m3 Value in cubic meters to convert
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public convertM3ToKwh(
        m3: number,
        observe?: 'body',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?: 'application/json';
            context?: HttpContext;
        },
    ): Observable<KWhValue>;
    public convertM3ToKwh(
        m3: number,
        observe?: 'response',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?: 'application/json';
            context?: HttpContext;
        },
    ): Observable<HttpResponse<KWhValue>>;
    public convertM3ToKwh(
        m3: number,
        observe?: 'events',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?: 'application/json';
            context?: HttpContext;
        },
    ): Observable<HttpEvent<KWhValue>>;
    public convertM3ToKwh(
        m3: number,
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: {
            httpHeaderAccept?: 'application/json';
            context?: HttpContext;
        },
    ): Observable<any> {
        if (m3 === null || m3 === undefined) {
            throw new Error(
                'Required parameter m3 was null or undefined when calling convertM3ToKwh.',
            );
        }

        let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
        if (m3 !== undefined && m3 !== null) {
            localVarQueryParameters = this.addToHttpParams(
                localVarQueryParameters,
                <any>m3,
                'm3',
            );
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined =
            options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = ['application/json'];
            localVarHttpHeaderAcceptSelected =
                this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set(
                'Accept',
                localVarHttpHeaderAcceptSelected,
            );
        }

        let localVarHttpContext: HttpContext | undefined =
            options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (
                this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
            ) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/demo/v1/conversions/convert-m3-to-kwh`;
        return this.httpClient.request<KWhValue>(
            'get',
            `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            },
        );
    }

    /**
     * Delete a photo of device
     * @param deviceId Device id
     * @param photoId The ID of the photo to delete
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deletePicture(
        deviceId: number,
        photoId: number,
        observe?: 'body',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: undefined; context?: HttpContext },
    ): Observable<any>;
    public deletePicture(
        deviceId: number,
        photoId: number,
        observe?: 'response',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: undefined; context?: HttpContext },
    ): Observable<HttpResponse<any>>;
    public deletePicture(
        deviceId: number,
        photoId: number,
        observe?: 'events',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: undefined; context?: HttpContext },
    ): Observable<HttpEvent<any>>;
    public deletePicture(
        deviceId: number,
        photoId: number,
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: { httpHeaderAccept?: undefined; context?: HttpContext },
    ): Observable<any> {
        if (deviceId === null || deviceId === undefined) {
            throw new Error(
                'Required parameter deviceId was null or undefined when calling deletePicture.',
            );
        }
        if (photoId === null || photoId === undefined) {
            throw new Error(
                'Required parameter photoId was null or undefined when calling deletePicture.',
            );
        }

        let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
        if (photoId !== undefined && photoId !== null) {
            localVarQueryParameters = this.addToHttpParams(
                localVarQueryParameters,
                <any>photoId,
                'photoId',
            );
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined =
            options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [];
            localVarHttpHeaderAcceptSelected =
                this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set(
                'Accept',
                localVarHttpHeaderAcceptSelected,
            );
        }

        let localVarHttpContext: HttpContext | undefined =
            options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (
                this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
            ) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/demo/v1/devices/${this.configuration.encodeParam({
            name: 'deviceId',
            value: deviceId,
            in: 'path',
            style: 'simple',
            explode: false,
            dataType: 'number',
            dataFormat: 'int64',
        })}/photo`;
        return this.httpClient.request<any>(
            'delete',
            `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            },
        );
    }

    /**
     * Get locations of installed devices (belonging to the queried system name)
     * @param systemName
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDeviceLocations(
        systemName: DeviceTypeSystemName,
        observe?: 'body',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?: 'application/json';
            context?: HttpContext;
        },
    ): Observable<Array<DeviceLocation>>;
    public getDeviceLocations(
        systemName: DeviceTypeSystemName,
        observe?: 'response',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?: 'application/json';
            context?: HttpContext;
        },
    ): Observable<HttpResponse<Array<DeviceLocation>>>;
    public getDeviceLocations(
        systemName: DeviceTypeSystemName,
        observe?: 'events',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?: 'application/json';
            context?: HttpContext;
        },
    ): Observable<HttpEvent<Array<DeviceLocation>>>;
    public getDeviceLocations(
        systemName: DeviceTypeSystemName,
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: {
            httpHeaderAccept?: 'application/json';
            context?: HttpContext;
        },
    ): Observable<any> {
        if (systemName === null || systemName === undefined) {
            throw new Error(
                'Required parameter systemName was null or undefined when calling getDeviceLocations.',
            );
        }

        let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
        if (systemName !== undefined && systemName !== null) {
            localVarQueryParameters = this.addToHttpParams(
                localVarQueryParameters,
                <any>systemName,
                'systemName',
            );
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined =
            options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = ['application/json'];
            localVarHttpHeaderAcceptSelected =
                this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set(
                'Accept',
                localVarHttpHeaderAcceptSelected,
            );
        }

        let localVarHttpContext: HttpContext | undefined =
            options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (
                this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
            ) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/demo/v1/device/locations`;
        return this.httpClient.request<Array<DeviceLocation>>(
            'get',
            `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            },
        );
    }

    /**
     * Get device photos by device id
     * @param deviceId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDevicePhotos(
        deviceId: number,
        observe?: 'body',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?: 'application/json';
            context?: HttpContext;
        },
    ): Observable<Array<Photo>>;
    public getDevicePhotos(
        deviceId: number,
        observe?: 'response',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?: 'application/json';
            context?: HttpContext;
        },
    ): Observable<HttpResponse<Array<Photo>>>;
    public getDevicePhotos(
        deviceId: number,
        observe?: 'events',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?: 'application/json';
            context?: HttpContext;
        },
    ): Observable<HttpEvent<Array<Photo>>>;
    public getDevicePhotos(
        deviceId: number,
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: {
            httpHeaderAccept?: 'application/json';
            context?: HttpContext;
        },
    ): Observable<any> {
        if (deviceId === null || deviceId === undefined) {
            throw new Error(
                'Required parameter deviceId was null or undefined when calling getDevicePhotos.',
            );
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined =
            options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = ['application/json'];
            localVarHttpHeaderAcceptSelected =
                this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set(
                'Accept',
                localVarHttpHeaderAcceptSelected,
            );
        }

        let localVarHttpContext: HttpContext | undefined =
            options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (
                this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
            ) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/demo/v1/devices/${this.configuration.encodeParam({
            name: 'deviceId',
            value: deviceId,
            in: 'path',
            style: 'simple',
            explode: false,
            dataType: 'number',
            dataFormat: 'int64',
        })}/photo`;
        return this.httpClient.request<Array<Photo>>(
            'get',
            `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            },
        );
    }

    /**
     * Get device types
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDeviceTypes(
        observe?: 'body',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?: 'application/json';
            context?: HttpContext;
        },
    ): Observable<Array<DeviceType>>;
    public getDeviceTypes(
        observe?: 'response',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?: 'application/json';
            context?: HttpContext;
        },
    ): Observable<HttpResponse<Array<DeviceType>>>;
    public getDeviceTypes(
        observe?: 'events',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?: 'application/json';
            context?: HttpContext;
        },
    ): Observable<HttpEvent<Array<DeviceType>>>;
    public getDeviceTypes(
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: {
            httpHeaderAccept?: 'application/json';
            context?: HttpContext;
        },
    ): Observable<any> {
        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined =
            options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = ['application/json'];
            localVarHttpHeaderAcceptSelected =
                this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set(
                'Accept',
                localVarHttpHeaderAcceptSelected,
            );
        }

        let localVarHttpContext: HttpContext | undefined =
            options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (
                this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
            ) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/demo/v1/device/types`;
        return this.httpClient.request<Array<DeviceType>>(
            'get',
            `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            },
        );
    }

    /**
     * Get meter by id
     * @param meterId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMeter(
        meterId: number,
        observe?: 'body',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?: 'application/json';
            context?: HttpContext;
        },
    ): Observable<MeterDevice>;
    public getMeter(
        meterId: number,
        observe?: 'response',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?: 'application/json';
            context?: HttpContext;
        },
    ): Observable<HttpResponse<MeterDevice>>;
    public getMeter(
        meterId: number,
        observe?: 'events',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?: 'application/json';
            context?: HttpContext;
        },
    ): Observable<HttpEvent<MeterDevice>>;
    public getMeter(
        meterId: number,
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: {
            httpHeaderAccept?: 'application/json';
            context?: HttpContext;
        },
    ): Observable<any> {
        if (meterId === null || meterId === undefined) {
            throw new Error(
                'Required parameter meterId was null or undefined when calling getMeter.',
            );
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined =
            options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = ['application/json'];
            localVarHttpHeaderAcceptSelected =
                this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set(
                'Accept',
                localVarHttpHeaderAcceptSelected,
            );
        }

        let localVarHttpContext: HttpContext | undefined =
            options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (
                this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
            ) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/demo/v1/meterdevices/${this.configuration.encodeParam(
            {
                name: 'meterId',
                value: meterId,
                in: 'path',
                style: 'simple',
                explode: false,
                dataType: 'number',
                dataFormat: 'int64',
            },
        )}`;
        return this.httpClient.request<MeterDevice>(
            'get',
            `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            },
        );
    }

    /**
     * Get meter types
     * @param systemName
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMeterTypes(
        systemName: DeviceTypeSystemName,
        observe?: 'body',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?: 'application/json';
            context?: HttpContext;
        },
    ): Observable<Array<GasMeterType>>;
    public getMeterTypes(
        systemName: DeviceTypeSystemName,
        observe?: 'response',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?: 'application/json';
            context?: HttpContext;
        },
    ): Observable<HttpResponse<Array<GasMeterType>>>;
    public getMeterTypes(
        systemName: DeviceTypeSystemName,
        observe?: 'events',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?: 'application/json';
            context?: HttpContext;
        },
    ): Observable<HttpEvent<Array<GasMeterType>>>;
    public getMeterTypes(
        systemName: DeviceTypeSystemName,
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: {
            httpHeaderAccept?: 'application/json';
            context?: HttpContext;
        },
    ): Observable<any> {
        if (systemName === null || systemName === undefined) {
            throw new Error(
                'Required parameter systemName was null or undefined when calling getMeterTypes.',
            );
        }

        let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
        if (systemName !== undefined && systemName !== null) {
            localVarQueryParameters = this.addToHttpParams(
                localVarQueryParameters,
                <any>systemName,
                'systemName',
            );
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined =
            options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = ['application/json'];
            localVarHttpHeaderAcceptSelected =
                this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set(
                'Accept',
                localVarHttpHeaderAcceptSelected,
            );
        }

        let localVarHttpContext: HttpContext | undefined =
            options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (
                this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
            ) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/demo/v1/gasmetertypes`;
        return this.httpClient.request<Array<GasMeterType>>(
            'get',
            `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            },
        );
    }

    /**
     * Get POD list
     * @param filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPods(
        filter: PODFilter,
        observe?: 'body',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?: 'application/json';
            context?: HttpContext;
        },
    ): Observable<Array<Pod>>;
    public getPods(
        filter: PODFilter,
        observe?: 'response',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?: 'application/json';
            context?: HttpContext;
        },
    ): Observable<HttpResponse<Array<Pod>>>;
    public getPods(
        filter: PODFilter,
        observe?: 'events',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?: 'application/json';
            context?: HttpContext;
        },
    ): Observable<HttpEvent<Array<Pod>>>;
    public getPods(
        filter: PODFilter,
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: {
            httpHeaderAccept?: 'application/json';
            context?: HttpContext;
        },
    ): Observable<any> {
        if (filter === null || filter === undefined) {
            throw new Error(
                'Required parameter filter was null or undefined when calling getPods.',
            );
        }

        let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
        if (filter !== undefined && filter !== null) {
            localVarQueryParameters = this.addToHttpParams(
                localVarQueryParameters,
                <any>filter,
                'filter',
            );
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined =
            options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = ['application/json'];
            localVarHttpHeaderAcceptSelected =
                this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set(
                'Accept',
                localVarHttpHeaderAcceptSelected,
            );
        }

        let localVarHttpContext: HttpContext | undefined =
            options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (
                this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
            ) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/demo/v1/pods`;
        return this.httpClient.request<Array<Pod>>(
            'get',
            `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            },
        );
    }

    /**
     * Upload photo to a device
     * @param deviceId Device id
     * @param photo
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uploadPhoto(
        deviceId: number,
        photo?: Blob,
        observe?: 'body',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: undefined; context?: HttpContext },
    ): Observable<any>;
    public uploadPhoto(
        deviceId: number,
        photo?: Blob,
        observe?: 'response',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: undefined; context?: HttpContext },
    ): Observable<HttpResponse<any>>;
    public uploadPhoto(
        deviceId: number,
        photo?: Blob,
        observe?: 'events',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: undefined; context?: HttpContext },
    ): Observable<HttpEvent<any>>;
    public uploadPhoto(
        deviceId: number,
        photo?: Blob,
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: { httpHeaderAccept?: undefined; context?: HttpContext },
    ): Observable<any> {
        if (deviceId === null || deviceId === undefined) {
            throw new Error(
                'Required parameter deviceId was null or undefined when calling uploadPhoto.',
            );
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined =
            options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [];
            localVarHttpHeaderAcceptSelected =
                this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set(
                'Accept',
                localVarHttpHeaderAcceptSelected,
            );
        }

        let localVarHttpContext: HttpContext | undefined =
            options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        // to determine the Content-Type header
        const consumes: string[] = ['multipart/form-data'];

        const canConsumeForm = this.canConsumeForm(consumes);

        let localVarFormParams: { append(param: string, value: any): any };
        let localVarUseForm = false;
        let localVarConvertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        localVarUseForm = canConsumeForm;
        if (localVarUseForm) {
            localVarFormParams = new FormData();
        } else {
            localVarFormParams = new HttpParams({ encoder: this.encoder });
        }

        if (photo !== undefined) {
            localVarFormParams =
                (localVarFormParams.append('photo', <any>photo) as any) ||
                localVarFormParams;
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (
                this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
            ) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/demo/v1/devices/${this.configuration.encodeParam({
            name: 'deviceId',
            value: deviceId,
            in: 'path',
            style: 'simple',
            explode: false,
            dataType: 'number',
            dataFormat: 'int64',
        })}/photo`;
        return this.httpClient.request<any>(
            'post',
            `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: localVarConvertFormParamsToString
                    ? localVarFormParams.toString()
                    : localVarFormParams,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            },
        );
    }
}
