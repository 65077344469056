import {
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
    switchMap,
    tap,
    catchError,
    first,
    timeout,
    takeUntil,
    take,
} from 'rxjs/operators';
import { throwError } from 'rxjs';

import { AuthState } from '../state/auth.facade';

const REQUEST_TIMEOUT = 10 * 1000;
const AUTH_HEADER_KEY = 'Authorization';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(private authState: AuthState) {}

    intercept(request: HttpRequest<any>, next: HttpHandler) {
        if (
            request.url.includes('token') ||
            request.url.includes('login') ||
            request.url.includes('no-intercept=true') ||
            request.headers.has(AUTH_HEADER_KEY) ||
            request.url.includes('3dalerts')
        ) {
            return next.handle(request);
        }

        return this.interceptApiRequest(request, next).pipe(
            catchError((err) => {
                console.error('Token interceptor failed for: ', request.url);
                console.error('Error: ', err.error);
                return throwError(() => err);
            }),
        );
    }

    private interceptApiRequest(request: HttpRequest<any>, next: HttpHandler) {
        return this.authState.selectAuthTokenFromQueryParam().pipe(
            take(1),
            switchMap((tokenFromQueryParams) => {
                if (tokenFromQueryParams === undefined) {
                    return this.authState.isTokenRefreshInProgress().pipe(
                        first((refreshInProgress) => !refreshInProgress),
                        switchMap(() =>
                            this.handleRequestIfNoRefreshInProgress(
                                request,
                                next,
                            ),
                        ),
                    );
                } else {
                    return next.handle(
                        request.clone({
                            setHeaders: {
                                [AUTH_HEADER_KEY]:
                                    'Token ' + tokenFromQueryParams,
                            },
                        }),
                    );
                }
            }),
        );
    }

    private handleRequestIfNoRefreshInProgress(
        request: HttpRequest<any>,
        next: HttpHandler,
    ) {
        return this.authState.getTokenExpiry().pipe(
            tap((expiry) => this.dispatchRefreshRequestIfExpired(expiry)),
            first((expiry) => this.isTokenValid(expiry)),
            timeout(REQUEST_TIMEOUT),
            takeUntil(this.getLoggedOut()),
            switchMap(() => this.handleRequestWithToken(request, next)),
        );
    }

    private handleRequestWithToken(
        request: HttpRequest<any>,
        next: HttpHandler,
    ) {
        {
            return this.authState.getAuthHeaderValue().pipe(
                switchMap((authHeaderValue) => {
                    return next.handle(
                        request.clone({
                            setHeaders: {
                                [AUTH_HEADER_KEY]: authHeaderValue,
                            },
                        }),
                    );
                }),
            );
        }
    }

    private dispatchRefreshRequestIfExpired(expiry: number | null) {
        if (this.isTokenInvalid(expiry)) {
            this.authState.refreshToken();
        }
    }

    private isTokenValid(tokenExpiry: number | null) {
        if (tokenExpiry === null) {
            return false;
        }

        return Date.now() < tokenExpiry;
    }

    private isTokenInvalid(tokenExpiry: number | null) {
        return !this.isTokenValid(tokenExpiry);
    }

    private getLoggedOut() {
        return this.authState
            .isLoggedIn()
            .pipe(first((value) => value === false));
    }
}
