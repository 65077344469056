/**
 * API definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
    HttpClient,
    HttpHeaders,
    HttpParams,
    HttpResponse,
    HttpEvent,
    HttpParameterCodec,
    HttpContext,
} from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

// @ts-ignore
import { ExportFormat } from '../model/exportFormat';
// @ts-ignore
import { Id } from '../model/id';
// @ts-ignore
import { MeasurementData } from '../model/measurementData';
// @ts-ignore
import { Resolution } from '../model/resolution';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';
import { format } from 'date-fns';

@Injectable({
    providedIn: 'root',
})
export class MeasurementService {
    protected basePath = 'https://test.digitalcap.network';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(
        protected httpClient: HttpClient,
        @Optional() @Inject(BASE_PATH) basePath: string | string[],
        @Optional() configuration: Configuration,
    ) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder =
            this.configuration.encoder || new CustomHttpParameterCodec();
    }

    // @ts-ignore
    private addToHttpParams(
        httpParams: HttpParams,
        value: any,
        key?: string,
    ): HttpParams {
        if (typeof value === 'object' && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(
        httpParams: HttpParams,
        value?: any,
        key?: string,
    ): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === 'object') {
            if (Array.isArray(value)) {
                (value as any[]).forEach(
                    (elem) =>
                        (httpParams = this.addToHttpParamsRecursive(
                            httpParams,
                            elem,
                            key,
                        )),
                );
            } else if (value instanceof Date) {
                if (key != null) {
                    if (value instanceof Date) {
                        let resDate = format(value, 'yyyy-MM-dd');
                        httpParams = httpParams.append(key, resDate);
                    }
                } else {
                    throw Error('key may not be null if value is Date');
                }
            } else {
                Object.keys(value).forEach(
                    (k) =>
                        (httpParams = this.addToHttpParamsRecursive(
                            httpParams,
                            value[k],
                            key != null ? `${key}.${k}` : k,
                        )),
                );
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error('key may not be null if value is not object or array');
        }
        return httpParams;
    }

    /**
     * Get measurement data in the given export format for device
     * @param deviceId
     * @param resolution
     * @param startDay
     * @param endDay
     * @param format
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exportMeasurementData(
        deviceId: number,
        resolution: Resolution,
        startDay: string,
        endDay: string,
        format: ExportFormat,
        observe?: 'body',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?: 'multipart/form-data';
            context?: HttpContext;
        },
    ): Observable<Blob>;
    public exportMeasurementData(
        deviceId: number,
        resolution: Resolution,
        startDay: string,
        endDay: string,
        format: ExportFormat,
        observe?: 'response',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?: 'multipart/form-data';
            context?: HttpContext;
        },
    ): Observable<HttpResponse<Blob>>;
    public exportMeasurementData(
        deviceId: number,
        resolution: Resolution,
        startDay: string,
        endDay: string,
        format: ExportFormat,
        observe?: 'events',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?: 'multipart/form-data';
            context?: HttpContext;
        },
    ): Observable<HttpEvent<Blob>>;
    public exportMeasurementData(
        deviceId: number,
        resolution: Resolution,
        startDay: string,
        endDay: string,
        format: ExportFormat,
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: {
            httpHeaderAccept?: 'multipart/form-data';
            context?: HttpContext;
        },
    ): Observable<any> {
        if (deviceId === null || deviceId === undefined) {
            throw new Error(
                'Required parameter deviceId was null or undefined when calling exportMeasurementData.',
            );
        }
        if (resolution === null || resolution === undefined) {
            throw new Error(
                'Required parameter resolution was null or undefined when calling exportMeasurementData.',
            );
        }
        if (startDay === null || startDay === undefined) {
            throw new Error(
                'Required parameter startDay was null or undefined when calling exportMeasurementData.',
            );
        }
        if (endDay === null || endDay === undefined) {
            throw new Error(
                'Required parameter endDay was null or undefined when calling exportMeasurementData.',
            );
        }
        if (format === null || format === undefined) {
            throw new Error(
                'Required parameter format was null or undefined when calling exportMeasurementData.',
            );
        }

        let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
        if (resolution !== undefined && resolution !== null) {
            localVarQueryParameters = this.addToHttpParams(
                localVarQueryParameters,
                <any>resolution,
                'resolution',
            );
        }
        if (startDay !== undefined && startDay !== null) {
            localVarQueryParameters = this.addToHttpParams(
                localVarQueryParameters,
                <any>startDay,
                'startDay',
            );
        }
        if (endDay !== undefined && endDay !== null) {
            localVarQueryParameters = this.addToHttpParams(
                localVarQueryParameters,
                <any>endDay,
                'endDay',
            );
        }
        if (format !== undefined && format !== null) {
            localVarQueryParameters = this.addToHttpParams(
                localVarQueryParameters,
                <any>format,
                'format',
            );
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined =
            options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = ['multipart/form-data'];
            localVarHttpHeaderAcceptSelected =
                this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set(
                'Accept',
                localVarHttpHeaderAcceptSelected,
            );
        }

        let localVarHttpContext: HttpContext | undefined =
            options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarPath = `/demo/v1/devices/${this.configuration.encodeParam({
            name: 'deviceId',
            value: deviceId,
            in: 'path',
            style: 'simple',
            explode: false,
            dataType: 'number',
            dataFormat: 'int64',
        })}/measurement/export`;
        return this.httpClient.request(
            'get',
            `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: 'blob',
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            },
        );
    }

    /**
     * Get device id by pod id
     * @param podId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDeviceIdByPOD(
        podId: number,
        observe?: 'body',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?: 'application/json';
            context?: HttpContext;
        },
    ): Observable<Id>;
    public getDeviceIdByPOD(
        podId: number,
        observe?: 'response',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?: 'application/json';
            context?: HttpContext;
        },
    ): Observable<HttpResponse<Id>>;
    public getDeviceIdByPOD(
        podId: number,
        observe?: 'events',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?: 'application/json';
            context?: HttpContext;
        },
    ): Observable<HttpEvent<Id>>;
    public getDeviceIdByPOD(
        podId: number,
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: {
            httpHeaderAccept?: 'application/json';
            context?: HttpContext;
        },
    ): Observable<any> {
        if (podId === null || podId === undefined) {
            throw new Error(
                'Required parameter podId was null or undefined when calling getDeviceIdByPOD.',
            );
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined =
            options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = ['application/json'];
            localVarHttpHeaderAcceptSelected =
                this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set(
                'Accept',
                localVarHttpHeaderAcceptSelected,
            );
        }

        let localVarHttpContext: HttpContext | undefined =
            options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (
                this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
            ) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/demo/v1/deviceid/${this.configuration.encodeParam({
            name: 'podId',
            value: podId,
            in: 'path',
            style: 'simple',
            explode: false,
            dataType: 'number',
            dataFormat: 'int64',
        })}`;
        return this.httpClient.request<Id>(
            'get',
            `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            },
        );
    }

    /**
     * Get measurement data for device
     * @param deviceId
     * @param resolution
     * @param startDay
     * @param endDay
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMeasurementData(
        deviceId: number,
        resolution: Resolution,
        startDay: string,
        endDay: string,
        observe?: 'body',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?: 'application/json';
            context?: HttpContext;
        },
    ): Observable<MeasurementData>;
    public getMeasurementData(
        deviceId: number,
        resolution: Resolution,
        startDay: string,
        endDay: string,
        observe?: 'response',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?: 'application/json';
            context?: HttpContext;
        },
    ): Observable<HttpResponse<MeasurementData>>;
    public getMeasurementData(
        deviceId: number,
        resolution: Resolution,
        startDay: string,
        endDay: string,
        observe?: 'events',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?: 'application/json';
            context?: HttpContext;
        },
    ): Observable<HttpEvent<MeasurementData>>;
    public getMeasurementData(
        deviceId: number,
        resolution: Resolution,
        startDay: string,
        endDay: string,
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: {
            httpHeaderAccept?: 'application/json';
            context?: HttpContext;
        },
    ): Observable<any> {
        if (deviceId === null || deviceId === undefined) {
            throw new Error(
                'Required parameter deviceId was null or undefined when calling getMeasurementData.',
            );
        }
        if (resolution === null || resolution === undefined) {
            throw new Error(
                'Required parameter resolution was null or undefined when calling getMeasurementData.',
            );
        }
        if (startDay === null || startDay === undefined) {
            throw new Error(
                'Required parameter startDay was null or undefined when calling getMeasurementData.',
            );
        }
        if (endDay === null || endDay === undefined) {
            throw new Error(
                'Required parameter endDay was null or undefined when calling getMeasurementData.',
            );
        }

        let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
        if (resolution !== undefined && resolution !== null) {
            localVarQueryParameters = this.addToHttpParams(
                localVarQueryParameters,
                <any>resolution,
                'resolution',
            );
        }
        if (startDay !== undefined && startDay !== null) {
            localVarQueryParameters = this.addToHttpParams(
                localVarQueryParameters,
                <any>startDay,
                'startDay',
            );
        }
        if (endDay !== undefined && endDay !== null) {
            localVarQueryParameters = this.addToHttpParams(
                localVarQueryParameters,
                <any>endDay,
                'endDay',
            );
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined =
            options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = ['application/json'];
            localVarHttpHeaderAcceptSelected =
                this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set(
                'Accept',
                localVarHttpHeaderAcceptSelected,
            );
        }

        let localVarHttpContext: HttpContext | undefined =
            options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (
                this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
            ) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/demo/v1/devices/${this.configuration.encodeParam({
            name: 'deviceId',
            value: deviceId,
            in: 'path',
            style: 'simple',
            explode: false,
            dataType: 'number',
            dataFormat: 'int64',
        })}/measurement`;
        return this.httpClient.request<MeasurementData>(
            'get',
            `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            },
        );
    }
}
