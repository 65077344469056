export function localizeForAGGrid() {
    const AG_GRID_LOCALIZE: { [key: string]: string } = {
        inRange: $localize`:@@grid_inRange:In range`,
        of: $localize`:@@grid_of:of`,
        page: $localize`:@@grid_page:Page`,
        searchOoo: $localize`:@@grid_searchOoo:Search...`,
        selectAll: $localize`:@@grid_selectAll:Select All`,
        to: $localize`:@@grid_to:to`,
    } as const;

    return AG_GRID_LOCALIZE;
}
