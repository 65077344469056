import {
    Component,
    ChangeDetectionStrategy,
    HostListener,
} from '@angular/core';
import {
    trigger,
    transition,
    style,
    animate,
    group,
    query,
    animateChild,
} from '@angular/animations';

import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

import { AuthState } from '@digital-cap-fe/platform/auth';

import { environment } from '../../../environments/environment';

import { UpdateCheckerService } from '../../services/update-checker/update-checker.service';

@Component({
    templateUrl: './init.component.html',
    styleUrls: ['./init.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('disappear', [
            transition(':leave', [
                group([
                    query('@scaleAndMoveUp', animateChild()),
                    animate(
                        '0.7s',
                        style({
                            opacity: 0,
                            filter: 'blur(8px)',
                        }),
                    ),
                ]),
            ]),
        ]),
        trigger('scaleAndMoveUp', [
            transition(':leave', [
                animate(
                    '0.7s',
                    style({
                        transform: 'scale(2) translateY(-30px)',
                    }),
                ),
            ]),
        ]),
    ],
})
export class InitComponent {
    initInProgress = this.authState.isInitInProgress();
    initialized = this.authState.isInitialized();

    isOnline = navigator.onLine;
    loadingIcon = faCircleNotch;

    @HostListener('window:online')
    @HostListener('window:offline')
    onlineStatusChanged() {
        this.isOnline = navigator.onLine;
    }

    constructor(private authState: AuthState, updates: UpdateCheckerService) {
        if (environment.production) {
            updates.listenForUpdates();
        }
    }
}
