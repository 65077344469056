export enum DeviceStatus {
    MANUFACTURED = 'MANUFACTURED',
    IN_STORAGE = 'IN_STORAGE',
    IN_TRANSIT = 'IN_TRANSIT',
    CALIBRATION = 'CALIBRATION',
    INSTALLATION_IN_PROGRESS = 'INSTALLATION_IN_PROGRESS',
    INSTALLED = 'INSTALLED',
    LOST = 'LOST',
}

export enum DeviceCondition {
    OK = 'OK',
    // S_ACC = 'S_ACC',
    // S_GYRO = 'S_GYRO',
    // S_TEMP = 'S_TEMP',
    // LOW_BAT = 'LOW_BAT',
    WAITING = 'WAITING',
    FAULTY = 'FAULTY',
    DEACTIVATING = 'DEACTIVATING',
    DEACTIVATED = 'DEACTIVATED',
    NO_OPERATION = 'NO_OPERATION',
   // COMMUNICATION_LOST = 'COMMUNICATION_LOST',
    ALARMED = 'ALARMED',
}

export enum AlarmType { 
    MOV = 'MOV',
    MAG = 'MAG',
    ACC = 'ACC',
    TEMP = 'TEMP',
    LOW_BAT = 'LOW_BAT',
    COM_LOST = 'COMMUNICATION_LOST',
}

export const alertConditions = [
    'S_ACC',
    'S_GYRO',
    'S_TEMP',
    'LOW_BAT',
    'COMMUNICATION_LOST',
];

export const acknowledgableAlerts = [
    'S_ACC',
    'S_GYRO',
    'S_TEMP',
    'LOW_BAT',
];

export const nonDeactivableCondition = [
    DeviceCondition.DEACTIVATED,
    DeviceCondition.DEACTIVATING,
    DeviceCondition.FAULTY,
    DeviceCondition.NO_OPERATION
]

export const enum AlertStatus {
    NEW = 'NEW',
    ACKNOWLEDGED = 'ACKNOWLEDGED',
    DISMISSED = 'DISMISSED',
}

export const enum AlertCondition {
    ACC = 'ACC',
    TEMP = 'TEMP',
    LOW_BAT = 'LOW_BAT',
    COMMUNICATION_LOST = 'COMMUNICATION_LOST',
}

export interface Alert {
    id: number;
    deviceSerialNumber: number;
    status: AlertStatus;
    reportedAt: string;
    notes: string;
    condition: AlertCondition;
}

export interface AlertSummary {
    deviceSerialNumber: number;
    reportedAt: string;
    condition: AlertCondition;
    count: number;
    alertIds: number[];
}

export type SerialNumber = number;

export const enum DeviceType { //TODO pipe-al átfordítani
    DIGITAL_CAP = 'digital-cap',
    PRESSURE_CAP = 'pressure-cap',
    IMPULSE_CAP = 'impulse-cap',
    CLIPON = 'clipon'
}

export interface Device {
    serialNumber: SerialNumber;
    lastUpdate: string;
    status: DeviceStatus;
    condition: DeviceCondition;
    installedAt: string;
    deviceType: DeviceType;
    assignedUserId: number;
    batteryLevel: number | null;
    batteryIndex: number | null;
    location: { lat: number; lng: number } | undefined;
    modemFwVersion: string | null;
    fwVersion: string;
    addressId: number;
    alarms: AlarmType[];
    registrationCycleCounter?: string;
    bootVersion?: string;
    deviceTemperature: number | null;
    CustomerDetails?: string;
    PODIdentifier?: string;
    decisionNeeded?: boolean;
    lastCommunication?: string;
    reportInterval?: number;
    offsetTime?: number;
    hardwareVersion?: number;
    lastCnt?: number;
    modemIMEI?: string;
}

export interface DevicePhoto {
    id: number;
    data: string;
    createdAt: string;
    /** User Id */
    createdBy: number;
    snapshot: { status: string; condition: string } | null | undefined;
}

export interface DevicePhotoFile {
    id: number | null;
    data: File;
    createdAt: string | null;
    createdBy: number | null;
    status: DeviceStatus;
    condition: DeviceCondition;
}

export enum MessageDirection {
    DOWN = 'DOWN',
    UP = 'UP',
}

export enum MessageStatus {
    ACKNOWLEDGED = 'ACKNOWLEDGED',
    CREATED = 'CREATED',
    EVALUATED = 'EVALUATED',
    RECEIVED = 'RECEIVED',
    SENT = 'SENT',
    SKIPPED = 'SKIPPED',
}

export interface DeviceMessageBase<COMMAND extends number = number> {
    cmd: COMMAND;
    serialNumber: SerialNumber | number[];
}

export interface DeviceMessage<
    PAYLOAD extends object = any,
    COMMAND extends number = number,
> extends DeviceMessageBase<COMMAND> {
    deviceCnt: number | null;
    deviceTime: number | null;
    direction: MessageDirection;
    id: number;
    ipAddress: string;
    lastUpdate: string;
    msgPayload: PAYLOAD | null;
    status: MessageStatus;
    serverTime: string;
    packetCount?: number;
    acknowledgedPacketCount?: number;
}

export interface DeviceFirmware {
    fwVersion: string;
    size: number;
    deviceCount: number;
}

export interface DeviceHardware {
    version: number;
    added?: string;
    description: object;
}

export interface ModemFirmware {
    version: string;
    createdAt: string;
    url: string;
    userId: number;
}

export interface DeviceHardwareDependency {
    id: number;
    createdAt?: string;
    createdBy?: number;
    hardware: number;
    modemFwVersion: string;
    oldFirmware: string;
    newFirmware: string;
    newBootLoader: string;
    oldBootLoader: string;
}

export interface DeviceBootloader {
    version: string;
    added: string;
    crc: number | null;
    size: number | null;
}

export type DeviceMessageStateEntity = DeviceMessage & { receivedAt?: number };
