import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { take, switchMap, map, catchError } from 'rxjs/operators';

import { TokenResponse, TokenAPIResponse } from '@digital-cap-fe/models';

import { AuthState } from '../state/auth.facade';
import { of } from 'rxjs';

@Injectable()
export class TokenService {
    constructor(private http: HttpClient, private authState: AuthState) {}

    refreshToken() {
        return this.authState.getRefreshToken().pipe(
            take(1),
            switchMap((refreshToken) => {
                return this.http
                    .post<TokenAPIResponse>('/api/users/refresh_token/', {
                        refresh_token: refreshToken,
                    })
                    .pipe(
                        map((response): TokenResponse => {
                            return {
                                token: response.token,
                                refreshToken: response.refresh_token,
                                expiry: response.token_expiry,
                            };
                        }),
                    );
            }),
        );
    }

    deleteToken() {
        return this.http.post<void>('/api/users/logout/', {}).pipe(
            catchError(() => {
                return of();
            }),
        );
    }
}
