export type BackendError = [errorCode: string, errorMessage: string];

export function isBackendError(value: any): value is BackendError {
    if (Array.isArray(value)) {
        if (typeof value[0] === 'string' && typeof value[1] === 'string') {
            if (value[0].charAt(0) === 'E') {
                return true;
            }
        }
    }

    return false;
}
