import {
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

const AUTH_HEADER_KEY = 'Authorization';

@Injectable()
export class GoBeTokenInterceptor implements HttpInterceptor {
    
    intercept(req: HttpRequest<any>, next: HttpHandler) {
        if (req.url.includes('3dalerts')) {
            return next.handle(
                req.clone({
                    setHeaders: {
                        [AUTH_HEADER_KEY]: 'Token ' + 652765872578652658656,
                        //'Content-Type': 'application/json; charset=utf-8',
                        Accept: '*/*',
                    },
                }),
            );
        }
        return next.handle(req);
    }
}
