/**
 * API definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type DeviceTypeSystemName = 'digital-cap' | 'pressure-cap' | 'impulse-cap';

export const DeviceTypeSystemName = {
    DigitalCap: 'digital-cap' as DeviceTypeSystemName,
    PressureCap: 'pressure-cap' as DeviceTypeSystemName,
    ImpulseCap: 'impulse-cap' as DeviceTypeSystemName
};

