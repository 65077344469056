/* eslint-disable @typescript-eslint/no-explicit-any */
const globalKeyForVersion = '__DIGITALCAP_VERSION_INFO';

/**
 * Upon calling set the version info in the global scope
 */
export function setVersionInformation(versionInfo: VersionInfo) {
    (globalThis as any)[globalKeyForVersion] = versionInfo;
}

export function getVersionInfo(): VersionInfo {
    return (globalThis as any)[globalKeyForVersion] ?? {};
}

export type VersionInfo = {
    tagName: string;
    gitShortSHA: string;
    infraVersionTag: string;
};
