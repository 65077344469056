import { EntityDefinition, EntityCollectionReducerMethods } from '@ngrx/data';

export class AdditionalEntityCollectionReducerMethods<
    T,
> extends EntityCollectionReducerMethods<T> {
    constructor(
        public entityName: string,
        public definition: EntityDefinition<T>,
    ) {
        super(entityName, definition);
    }
}
