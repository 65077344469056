import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { MatSnackBarModule } from '@angular/material/snack-bar';

import { TokenInterceptor } from './interceptors/token.interceptor';

import { reducer, authFeatureKey } from './state/auth.reducer';
import { AuthEffects } from './state/auth.effects';
import { AuthState } from './state/auth.facade';

import { InitGuard } from './guards/init.guard';

import { TokenService } from './services/token.service';
import { UserService } from './services/user.service';
import { GoBeTokenInterceptor } from './interceptors/gobe-token.interceptor';

@NgModule({
    imports: [
        StoreModule.forFeature(authFeatureKey, reducer),
        EffectsModule.forFeature([AuthEffects]),
        MatSnackBarModule,
    ],
    providers: [
        AuthState,
        TokenService,
        InitGuard,
        UserService,
        { provide: HTTP_INTERCEPTORS, multi: true, useClass: TokenInterceptor },
        {
            provide: HTTP_INTERCEPTORS,
            multi: true,
            useClass: GoBeTokenInterceptor,
        },
    ],
})
export class AuthModule {}
