import { ErrorHandler, ModuleWithProviders, NgModule } from '@angular/core';
import { Action, MetaReducer, META_REDUCERS } from '@ngrx/store';

import LogRocket from 'logrocket';

import createNgrxMiddleware from 'logrocket-ngrx';

import { isNonNullish } from '@digital-cap-fe/utilities';

import { MonitoringErrorHandler } from './error-handler';
import { MonitoringService } from './monitoring.service';

const redactedString = '**redacted**';

const logrocketMiddleware = createNgrxMiddleware(LogRocket, {
    actionSanitizer: (action: Action) => {
        if (
            'password' in action ||
            'token' in action ||
            'refreshToken' in action
        ) {
            return {
                ...(action as Action),
                token: redactedString,
                refreshToken: redactedString,
                password: redactedString,
            };
        }
        return action;
    },
    stateSanitizer: (state?: any) => {
        if (isNonNullish(state?.auth)) {
            return {
                ...state,
                auth: {
                    ...state.auth,
                    token: isNonNullish(state.auth.token)
                        ? redactedString
                        : state.auth.token,
                    refreshToken: isNonNullish(state.auth.refreshToken)
                        ? redactedString
                        : state.auth.token,
                },
            };
        }

        return state;
    },
});

export function getMetaReducers(): MetaReducer {
    return logrocketMiddleware;
}

@NgModule()
export class MonitoringModule {
    constructor() {
        const config = this.configuration;

        const release =
            typeof config.release === 'string'
                ? config.release
                : config.release?.();

        if (config.enabled) {
            LogRocket.init(config.logRocketAppId, {
                release,
                dom: { baseHref: config.baseHref },
                browser: {
                    urlSanitizer: (url) => {
                        let sanitizedUrl = url;

                        sanitizedUrl = sanitizedUrl.replace(
                            /token=([^&]*)/,
                            'token=' + redactedString,
                        );

                        return sanitizedUrl;
                    },
                },
                network: {
                    responseSanitizer: (response) => {
                        if (
                            isNonNullish(response.body) &&
                            typeof response.body === 'string'
                        ) {
                            if (response.body.includes('refresh_token')) {
                                response.body = response.body.replace(
                                    /refresh_token\":\"([^\"]*)/,
                                    'refresh_token":"' + redactedString,
                                );
                            }

                            if (response.body.includes('token')) {
                                response.body = response.body.replace(
                                    /\"token\":\"([^\"]*)/,
                                    '"token":"' + redactedString,
                                );
                            }
                        }

                        return response;
                    },
                    requestSanitizer: (request) => {
                        if (request.headers.Authorization) {
                            request.headers.Authorization = redactedString;
                        }

                        if (
                            isNonNullish(request.body) &&
                            typeof request.body === 'string'
                        ) {
                            if (request.body.includes('password')) {
                                request.body = request.body.replace(
                                    /password\":\"([^\"]*)/,
                                    'password":"' + redactedString,
                                );
                            }

                            if (request.body.includes('refresh_token')) {
                                request.body = request.body.replace(
                                    /refresh_token\":\"([^\"]*)/,
                                    'refresh_token":"' + redactedString,
                                );
                            }
                        }

                        return request;
                    },
                },
            });
        }
    }

    private get configuration() {
        return MonitoringModule.configuration;
    }

    static configuration: MonitoringConfiguration;

    static startMonitoring(
        config: MonitoringConfiguration,
    ): ModuleWithProviders<MonitoringModule> {
        MonitoringModule.configuration = config;
        return {
            ngModule: MonitoringModule,
            providers: config.enabled
                ? [
                      {
                          provide: META_REDUCERS,
                          useFactory: getMetaReducers,
                          multi: true,
                      },
                      MonitoringService,
                      {
                          provide: ErrorHandler,
                          useClass: MonitoringErrorHandler,
                      },
                  ]
                : [],
        };
    }
}

export type MonitoringConfiguration = {
    enabled: boolean;
    logRocketAppId: string;
    release?: string | (() => string);
    baseHref?: string;
};
