/**
 * API definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type Resolution = 'MONTHLY' | 'WEEKLY' | 'DAILY' | 'HOURLY';

export const Resolution = {
    Monthly: 'MONTHLY' as Resolution,
    Weekly: 'WEEKLY' as Resolution,
    Daily: 'DAILY' as Resolution,
    Hourly: 'HOURLY' as Resolution
};

