export function isValidPOD(pod: string | null | undefined): boolean {
    // valid if field is empty
    if (!pod) {
        return true;
    }

    // invalid if length isn't 16 or not started with 39N or contains illegal chars
    const regex = new RegExp('^39N[0-9A-Z-]{13}$');
    if (!regex.test(pod)) {
        return false;
    }

    // invalid if checksum of chars #1-#15 not equals to char #16
    const codeTable = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ-';
    let checksum = 0;
    for (let index = 0; index < pod.length - 1; index++) {
        checksum += codeTable.indexOf(pod.charAt(index)) * (16 - index);
    }
    checksum = 36 - ((checksum - 1) % 37);
    return codeTable.charAt(checksum) === pod.charAt(15);
}
