import { createFeatureSelector, createSelector } from '@ngrx/store';

import { Permission, User } from '@digital-cap-fe/models';

import * as fromAuth from './auth.reducer';

export const selectAuthState = createFeatureSelector<fromAuth.State>(
    fromAuth.authFeatureKey,
);

export const selectToken = createSelector(
    selectAuthState,
    fromAuth.selectToken,
);

export const selectTokenExpiry = createSelector(
    selectAuthState,
    fromAuth.selectExpiry,
);

export const selectRefreshToken = createSelector(
    selectAuthState,
    fromAuth.selectRefreshToken,
);

export const selectIsLoggedIn = createSelector(
    selectRefreshToken,
    (refreshToken) => !!refreshToken,
);

export const selectTokenRefreshInProgress = createSelector(
    selectAuthState,
    fromAuth.selectTokenRefreshInProgress,
);

export const selectLanguageChangeInProgress = createSelector(
    selectAuthState,
    fromAuth.selectLanguageChangeInProgress,
);

export const selectPasswordChangeInProgress = createSelector(
    selectAuthState,
    fromAuth.selectPasswordChangeInProgress,
);
export const selectPasswordResetRequestInProgress = createSelector(
    selectAuthState,
    fromAuth.selectPasswordResetRequestInProgress,
);

export const selectInitInProgress = createSelector(
    selectAuthState,
    fromAuth.selectInitInProgress,
);

export const selectInitialized = createSelector(
    selectAuthState,
    fromAuth.selectInitialized,
);

export const selectUser = createSelector(selectAuthState, fromAuth.selectUser);

export const selectUserId = createSelector(selectUser, (user) => {
    return user?.id ?? null;
});

export const selectIfUserHasPermission = createSelector(
    selectUser,
    (user: User | null, permission: Permission) => {
        return user?.permissions.includes(permission) ?? false;
    },
);

export const selectUserPermissions = createSelector(selectUser, (user) => {
    return user?.permissions ?? [];
});
