import { trigger, animate, transition, style } from '@angular/animations';

export const fadeInAnimationName = 'fadeAnimation';

export const fadeInAnimation = (name = fadeInAnimationName, timing = '100ms') =>
    trigger(name, [
        transition(':enter', [
            style({ opacity: 0 }),
            animate(timing, style({ opacity: 1 })),
        ]),
        transition(':leave', [animate(timing, style({ opacity: 0 }))]),
    ]);
