import { DeviceMessage } from './device';

export const DeviceMessageCommand = {
    Sabotage: 32,
    Low_Battery: 33,
    Heartbeat: 34,
    Set_Time: 35,
    Set_Heartbeat_Frequency: 36,
    Set_Parameters: 37,
    Set_Mode: 38,
    IMU_Data_Collection: 40,
    Magnetometer_Data_Collection: 41,
    Temperature_Sabotage: 42,
    Calibration_Message: 43,
    Install: 48,
    Crash_Report: 49,
    Reset_Device: 50,
    Modem_FW_Version: 52,
    Impulse_Report: 55,
    Impulse_Map: 56,
    Temperature_Report: 57,
    Impulse_Counter: 80,
    Firmware_Update: 144,
    Firmware_Apply: 145,
    Acknowledge: 254,
    Statistic_Pressure: 65,
    Actual_Pressure: 64,
} as const;

export interface PressureMessagePayload {
    pressure: number;
    temperature: number;
}
export interface PressureMessage
    extends DeviceMessage<
        PressureMessagePayload,
        typeof DeviceMessageCommand.Actual_Pressure
    > {}

export interface HeartbeatMessagePayload {
    actualPosX: number;
    actualPosY: number;
    actualPosZ: number;
    actualTemp: number;
    avgTemp: number;
    batteryLevel: number;
    consumption: number;
    fwVersion: string;
    sensorReport: number;
    wakeupCnt: number;
}
export interface HeartbeatMessage
    extends DeviceMessage<
        HeartbeatMessagePayload,
        typeof DeviceMessageCommand.Heartbeat
    > {}
