import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { User } from '@digital-cap-fe/models';

@Injectable()
export class UserService {
    constructor(private http: HttpClient) {}

    getCurrentUserDetails() {
        return this.http.get<User>('/api/users/me/');
    }

    patchCurrentUser(user: Partial<User>) {
        return this.http.patch<User>('/api/users/me/', user);
    }

    requestPasswordResetEmail(email: string) {
        return this.http.post<void>(
            '/api/users/password-reset-email/?no-intercept=true',
            {
                email,
            },
        );
    }
}
