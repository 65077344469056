import { createAction, props } from '@ngrx/store';

export const subscribeToAPIUpdates = createAction(
    '[Messages] Subscribe to messages stream',
);

export const streamError = createAction(
    '[Messages] Stream error',
    props<{ errorMessage: string }>(),
);
