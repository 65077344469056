import { DOCUMENT } from '@angular/common';
import { LOCALE_ID } from '@angular/core';
import { Component, Inject } from '@angular/core';

@Component({
    selector: 'digital-cap-root',
    template: `
        <router-outlet></router-outlet
        ><router-outlet name="modal-outlet"></router-outlet>
    `,
})
export class AppComponent {
    constructor(
        @Inject(DOCUMENT) private document: Document,
        @Inject(LOCALE_ID) private locale: string,
    ) {
        this.document.documentElement.lang = this.locale.split('-')[0];
    }
}
