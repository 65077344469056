import { isBackendError } from '@digital-cap-fe/models';

export function getErrorDescription(error: any) {
    const ERROR_CODES: { [id: string]: string | undefined } = {
        E002: $localize`:@@errorCodeE002:Invalid parameters given for the request`,
        E003: $localize`:@@errorCodeE003:The current session token seems to be invalid or expired. Please try to clear your browser's storage and log in again`,
        E005: $localize`:@@errorCodeE005:No permission for this method of the user`,
        E011: $localize`:@@errorCodeE011:The object that you are trying to add already exists in the system`,
        E014: $localize`:@@errorCodeE014:An unexpected parameter found in request`,
        E028: $localize`:@@errorCodeE028:The provided file seems to be of the wrong type`,
        E029: $localize`:@@errorCodeE029:This firmware version already exists in the system`,
        E031: $localize`:@@errorCodeE031:The firmware update cannot be performed on these devices`,
        E034: $localize`:@@errorCodeE034:Invalid payload block in request`,
        E046: $localize`:@@errorCodeE046:This operation would result too much data`,
    } as const;

    if (isBackendError(error)) {
        return ERROR_CODES[error[0]] ?? error[1];
    }

    return JSON.stringify(error);
}
