import {
    Component,
    ChangeDetectionStrategy,
    Inject,
    HostListener,
    ElementRef,
    Optional,
    HostBinding,
} from '@angular/core';
import { Router } from '@angular/router';
import {
    fadeInAnimation,
    fadeInAnimationName,
} from '@digital-cap-fe/animations';

import { IS_ROUTED_MODAL, MODAL_OUTLET } from '../modal-config.tokens';

@Component({
    selector: 'digital-cap-modal-wrapper',
    templateUrl: './modal-wrapper.component.html',
    styleUrls: ['./modal-wrapper.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [fadeInAnimation()],
})
export class ModalWrapperComponent {
    constructor(
        private elementRef: ElementRef<HTMLElement>,
        private router: Router,
        @Optional()
        @Inject(IS_ROUTED_MODAL)
        private isRoutedModal: boolean | null,
        @Optional()
        @Inject(MODAL_OUTLET)
        private modalOutletName: string | null,
    ) {}

    @HostBinding('@' + fadeInAnimationName)
    public fadeIn = true;

    @HostListener('click', ['$event'])
    protected backdropClickHandler(event: MouseEvent) {
        if (
            event.target !== null &&
            (event.target as HTMLElement).contains(
                this.elementRef.nativeElement,
            )
        ) {
            this.closeModal();
        }
    }

    @HostListener('document:keyup.escape')
    public closeModal() {
        if (this.isRoutedModal === true && this.modalOutletName !== null) {
            this.router.navigate(
                [
                    '',
                    {
                        outlets: {
                            [this.modalOutletName]: null,
                        },
                    },
                ],
                {
                    skipLocationChange: false,
                },
            );
        }
    }
}
