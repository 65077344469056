import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { map } from 'rxjs/operators';

import { Permission } from '@digital-cap-fe/models';

import { refreshAuthToken, logout } from './auth.actions';
import { State } from './auth.reducer';
import {
    selectIsLoggedIn,
    selectTokenRefreshInProgress,
    selectTokenExpiry,
    selectRefreshToken,
    selectToken,
    selectInitInProgress,
    selectInitialized,
    selectIfUserHasPermission,
    selectUserPermissions,
    selectUser,
} from './auth.selectors';
import { selectQueryParam } from '@digital-cap-fe/state/router-state';

@Injectable()
export class AuthState {
    constructor(private store: Store<State>) {}

    isLoggedIn() {
        return this.store.select(selectIsLoggedIn);
    }

    isInitInProgress() {
        return this.store.select(selectInitInProgress);
    }

    isInitialized() {
        return this.store.select(selectInitialized);
    }

    isTokenRefreshInProgress() {
        return this.store.select(selectTokenRefreshInProgress);
    }

    getTokenExpiry() {
        return this.store.select(selectTokenExpiry);
    }

    getRefreshToken() {
        return this.store.select(selectRefreshToken).pipe(
            map((refreshToken) => {
                if (refreshToken === null) {
                    throw new Error('RefreshToken should not be null here');
                }
                return refreshToken;
            }),
        );
    }

    getAuthToken() {
        return this.store.select(selectToken);
    }

    getAuthHeaderValue() {
        return this.store.select(selectToken).pipe(
            map((token) => {
                if (token === null) {
                    throw new Error('Token should really not be null here');
                }
                return 'Token ' + token;
            }),
        );
    }

    refreshToken() {
        this.store.dispatch(refreshAuthToken());
    }

    logout() {
        this.store.dispatch(logout());
    }

    getIfUserHasPermission(permission: Permission) {
        return this.store.select(selectIfUserHasPermission, permission);
    }

    getIfUserHasAllPermissions(...permissionsToCheck: Permission[]) {
        return this.store.select(selectUserPermissions).pipe(
            map((userPermissions) => {
                return permissionsToCheck.every((permission) =>
                    userPermissions.includes(permission),
                );
            }),
        );
    }

    getIfUserHasSomePermissions(...permissionsToCheck: Permission[]) {
        return this.store.select(selectUserPermissions).pipe(
            map((userPermissions) => {
                return permissionsToCheck.some((permission) =>
                    userPermissions.includes(permission),
                );
            }),
        );
    }

    selectAuthTokenFromQueryParam() {
        return this.store.pipe(select(selectQueryParam('token')));
    }

    getUser() {
        return this.store.select(selectUser);
    }
}
