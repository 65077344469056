import {
    GridOptions,
    GridReadyEvent,
    PaginationChangedEvent,
} from 'ag-grid-community';
import { localizeForAGGrid } from './ag-grid-localize';

export function initializeGridOptions(
    frameworkComponents: any,
    gridReady: (event: GridReadyEvent) => void,
    paginationChanged: (event: PaginationChangedEvent) => void,
): GridOptions {
    return {
        frameworkComponents,
        suppressCellSelection: true,
        enableCellTextSelection: true,
        localeText: localizeForAGGrid(),
        defaultColDef: {
            sortable: true,
            suppressMenu: true,
            filter: 'agTextColumnFilter',
            floatingFilter: true,
            floatingFilterComponentParams: { suppressFilterButton: true },
            autoHeight: true,
        },
        onGridReady: gridReady,
        onGridSizeChanged: (params) => params.api.sizeColumnsToFit(),
        onGridColumnsChanged: (params) => params.api.sizeColumnsToFit(),
        onPaginationChanged: paginationChanged,
    };
}

export function compareDates(
    filterDate: Date,
    cellValue: string | number | Date | null,
) {
    if (cellValue === null) {
        return 0;
    }
    const cellDate = new Date(cellValue);
    cellDate.setHours(0, 0, 0, 0);
    if (cellDate < filterDate) {
        return -1;
    } else if (cellDate > filterDate) {
        return 1;
    }
    return 0;
}
