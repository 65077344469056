import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { map } from 'rxjs/operators';

import { AuthModule } from '../auth.module';
import { AuthState } from '../state/auth.facade';

@Injectable({
    providedIn: AuthModule,
})
export class NotAuthenticatedGuard implements CanActivate {
    constructor(private router: Router, private authState: AuthState) {}

    canActivate() {
        return this.authState.isLoggedIn().pipe(
            map((isLoggedIn) => {
                return !isLoggedIn || this.router.createUrlTree(['/home']);
            }),
        );
    }
}
