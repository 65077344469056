import equal from 'fast-deep-equal/es6';
import { data } from 'autoprefixer';

type Entity<T> = {
    [index: number]: T;
};

export type ObjectOf<
    Type,
    ExcludedKeys extends keyof never = '',
    TypeToCastTheValues = never,
> = Record<keyof Omit<Type, ExcludedKeys>, TypeToCastTheValues>;

// eslint-disable-next-line @typescript-eslint/ban-types
export function createEntity<T extends object>(
    objectArray: T[],
    entityKey: keyof T,
    entities: Entity<T> = {},
) {
    return objectArray.reduce(
        (acc, object: T) => {
            return {
                ...acc,
                [object[entityKey] as unknown as number]: {
                    ...entities[object[entityKey] as unknown as number],
                    ...object,
                },
            };
        },
        { ...entities },
    );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function deepEquals(a: any, b: any) {
    return equal(a, b);
}

export function entityDataModuleImportGuard<T>(
    shouldNotBeNull: T | null,
): asserts shouldNotBeNull is NonNullable<T> {
    if (shouldNotBeNull === null) {
        throw new Error(
            'Please make sure that you have imported the EntityDataModule.forRoot() in the root module of the application',
        );
    }
}
