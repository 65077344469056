import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { InitComponent } from './containers/init/init.component';
import {
    AuthGuard,
    NotAuthenticatedGuard,
    InitGuard,
} from '@digital-cap-fe/platform/auth';

const routes: Routes = [
    { path: '', pathMatch: 'full', redirectTo: '/home' },
    {
        path: '',
        component: InitComponent,
        children: [
            {
                path: 'home',
                children: [],
                canActivate: [AuthGuard, InitGuard],
            },
            {
                path: 'main',
                canActivate: [AuthGuard],
                loadChildren: () =>
                    import('@digital-cap-fe/features/main-layout').then(
                        (m) => m.MainLayoutModule,
                    ),
            },
            {
                path: 'login',
                canActivate: [NotAuthenticatedGuard],
                loadChildren: () =>
                    import('./modules/login/login.module').then(
                        (m) => m.LoginModule,
                    ),
            },
            {
                path: 'reset-password',
                canActivate: [NotAuthenticatedGuard],
                loadChildren: () =>
                    import('@digital-cap-fe/features/password-change').then(
                        (module) => module.PasswordChangeModule,
                    ),
            },
            {
                path: 'request-password-reset',
                canActivate: [NotAuthenticatedGuard],
                loadChildren: () =>
                    import(
                        '@digital-cap-fe/features/request-password-reset'
                    ).then((module) => module.RequestPasswordResetModule),
            },
        ],
    },
    { path: '**', redirectTo: '/home' },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            initialNavigation: 'enabled',
            enableTracing: false,
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
