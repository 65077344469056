import { registerLocaleData } from '@angular/common';
import { fetchTranslations, getBrowserCultureLang } from '@locl/core';
import { languageStorageKey } from '../../../libs/platform/auth/src/lib/state/auth.effects';

const LOCALE_SEPARATOR = '-';
const DEFAULT_LOCALE = 'en-GB';
let LOCALE_ID = DEFAULT_LOCALE;

export function getLocaleID() {
    return LOCALE_ID;
}

export function getUserLocale() {
    let language = localStorage.getItem(languageStorageKey);

    if (!language) {
        language = getBrowserCultureLang();
    }

    const localeParts = language.split(LOCALE_SEPARATOR);

    if (localeParts[1] !== undefined) {
        return `${localeParts[0].toLowerCase()}-${localeParts[1].toUpperCase()}`;
    }

    return language;
}

export function getTranslationsFileLocation(locale = DEFAULT_LOCALE) {
    return `assets/i18n/locale-${locale.split(LOCALE_SEPARATOR)[0]}.json`;
}

export async function initializeLocale() {
    const locale = getUserLocale();
    const translationFileLocation = getTranslationsFileLocation(locale);

    try {
        await fetchTranslations(translationFileLocation);
    } catch {
        console.warn(
            `Failed to load translation file: ${translationFileLocation}`,
        );
        await fetchTranslations(getTranslationsFileLocation());
    } finally {
        try {
            await initializeAngularLocale(locale);
        } catch {
            console.warn(
                'Failed to initialize Angular locale settings. Falling back to English',
            );
            await initializeAngularLocale(DEFAULT_LOCALE);
        }
    }
}

/**
 * Load the appropriate locale module for pipe localization
 */
export async function initializeAngularLocale(localeId: string): Promise<void> {
    const localeModule = await import(
        /* webpackMode: "lazy-once", webpackInclude: /(en-GB|en|hu|it)\.mjs$/, webpackChunkName: "angular-locales" */
        `node_modules/@angular/common/locales/${localeId}.mjs`
    );
    LOCALE_ID = localeId;

    return registerLocaleData(localeModule.default, localeId);
}
