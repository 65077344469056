import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';

import { concat, interval } from 'rxjs';
import { first } from 'rxjs/operators';

/**
 * Update checker service to prompt the user and install the newly deployed application version
 */
@Injectable()
export class UpdateCheckerService {
    /**
     * Dependencies for the service
     */
    constructor(private appRef: ApplicationRef, private updates: SwUpdate) {}

    private updateAvailableDialogText = $localize`:@@updateCheckDialogText:There is a new version available!\n\nCan we reload and install the new version?`;

    /**
     * Calling this function will start checking for updates, and prompts the user for installation if there is one available.
     */
    public listenForUpdates() {
        // Allow the app to stabilize first, before starting polling for updates with `interval()`.
        const appIsStable$ = this.appRef.isStable.pipe(
            first((isStable) => isStable === true),
        );

        const everyMinute$ = interval(60 * 1000);
        const everyMinuteOnceAppIsStable$ = concat(appIsStable$, everyMinute$);

        everyMinuteOnceAppIsStable$.subscribe(() =>
            this.updates.checkForUpdate().catch((err) => {
                console.error(
                    'Error occurred while checking for updates:',
                    err,
                );
                return Promise.resolve();
            }),
        );

        this.updates.available.subscribe(() => {
            if (confirm(this.updateAvailableDialogText)) {
                this.updates
                    .activateUpdate()
                    .then(() => document.location.reload());
            }
        });
    }
}
