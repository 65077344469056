// TODO remove this localize import, don't use translations and non-pure functions as utilities
import '@angular/localize/init';

export * from './lib/ag-grid-localize';
export * from './lib/ag-grid-utility';
export * from './lib/date';
export * from './lib/entities';
export * from './lib/enums';
export * from './lib/filter-check';
export * from './lib/location';
export * from './lib/nullish-check';
export * from './lib/pod-validator';
export * from './lib/response';
export * from './lib/url-param-serializer';
export * from './lib/utf8-validator';
export * from './lib/version';

// slicing because of DCNF-417
export function serialNumberSlicer(serial: string) {
    return parseInt(serial.slice(-8));
}
